/*----------------------------------------
[Master Stylesheet]

Project:  Unitok HTML Template
Version:  1.0.1
Primary use:  Unitok HTML Template
----------------------------------------*/
/*----------------------------------------
[Table of contents]

1. Fonts
2. Common styles 
3. Header
4. Main
5. Breadcrumb
6. Paginator
7. Filter
8. Home
9. Hero
10. Card
11. Post
12. Asset
13. Sellers
14. Activity
15. Partners
16. Feature
17. Step
18. Knowledge
19. FAQ
20. Сollection
21. Author
22. Profile
23. Contacts
24. Article
25. Share
26. Comments
27. Footer
28. Sign
29. Page 404
30. Modal
31. Select2
32. Scrollbar-track
33. Color
34. Background-color
35. Blog
----------------------------------------*/
/*==============================
  Fonts
==============================*/
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@500;700&family=Inter:wght@500;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nanum+Myeongjo&display=swap');
/*==============================
  Common styles
==============================*/
* {
  /*** Color ***/
  --primary-color: #404040;
  --title-color: #202020;
  --black-color: #000000;
  --primary-bg-color: #fff;
  --secondary-bg-color: #f4f5f6;
  --hover-link-color: #4a8af4;
  --border-color: #e0e0e0;
  --button-bg-color: #ff9700;
  --button-color: #fff;
  --button-bg-color-hover: #e0e0e0;
  --button-color-hover: #202020;
  --banner-bg-color: #100034;
  --orange-color: #ff9700;
  /*** Font ***/
  /* --head-font: "Roboto", Helvetica, Arial, sans-serif;
  --text-font: "Nanum Myeongjo", Helvetica, Arial, serif; */
  --head-font: 'Roboto', Helvetica, Arial, serif;

  --text-font: 'Roboto', Helvetica, Arial, sans-serif;
  --header-h1h2-font: 'Nanum Myeongjo';
  --gutter: 32px;
}

h1,
h2 {
  font-family: var(--header-h1h2-font) !important;
}
.main {
  min-width: 250px !important;
}
.relative {
  position: relative;
}
.head__text {
  font-family: var(--head-font);
}
.discover-dots-ul {
  margin: 0px;
  padding: 0px;
  font-size: 0px;
}
.discover-dots-ul li {
  margin: 0px 4px !important;
  height: auto !important;
}
.dots-discover {
  width: 15px;
  background-color: #ffffff;
  height: 6px;
  border-radius: 20px;
}
.MuiSkeleton-text {
  transform: none !important;
}
/* @media (aspect-ratio: 1/1){
  .aspect-square {

  }
} */

.slick-active .dots-discover {
  background-color: #ff9700 !important;
}

.card_image_margin {
  /* padding: 0px 0px !important; */
  /* border-radius: 22px; */
  /* text-align: center; */
}

.p-gutter {
  padding-left: calc(var(--gutter) / 2);
  padding-right: calc(var(--gutter) / 2);
}
.font-head {
  font-family: var(--head-font) !important;
}

.font-text {
  font-family: var(--text-font) !important;
}

.Component-root-7 {
  font-family: var(--text-font) !important;
  color: var(--title-color) !important;
  font-weight: 400;
}
.avatar-icon {
  overflow: hidden !important;
}
/* .hidden {
  display: none;
} */
.showen {
  display: block;
}
.text-primary {
  color: var(--primary-color);
}
.text-title {
  color: var(--title-color);
}
.text-black {
  color: var(--black-color);
}

.text-hover-link {
  color: var(--hover-link-color);
}

.bg-primary {
  background-color: var(--primary-bg-color);
}

.bg-secondary {
  background-color: var(--secondary-bg-color);
}
.px-30px {
  margin-left: 30px;
  margin-right: 30px;
}
html {
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  min-width: 200px;
}
html,
body {
  height: 100%;
}
body {
  font-family: var(--text-font);
  font-weight: 400;
  background-color: var(--primary-bg-color);
  -webkit-font-smoothing: antialiased;
}
.dialog_borderBottom {
  border-bottom: 1px solid var(--border-color);
}
button {
  padding: 0;
  border: none;
  background-color: transparent;
  transition: 0.5s ease;
  transition-property: color, background-color, border-color, box-shadow;
  cursor: pointer;
}
button:focus {
  outline: none;
}
a {
  transition: 0.5s ease;
  transition-property: color, background-color, border-color, box-shadow;
}
a:hover,
a:active,
a:focus {
  outline: none;
  text-decoration: none;
}
input,
textarea,
select {
  padding: 0;
  margin: 0;
  border-radius: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  appearance: none;
  box-shadow: none;
  transition: 0.5s ease;
  transition-property: color, border-color, box-shadow;
}
input:focus,
textarea:focus,
select:focus {
  outline: none;
}
select::-ms-expand {
  display: none;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type='number'] {
  -moz-appearance: textfield;
}
ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
::-moz-selection {
  background: var(--title-color);
  color: var(--border-color);
  text-shadow: none;
}
::selection {
  background: var(--title-color);
  color: var(--border-color);
  text-shadow: none;
}
::-webkit-input-placeholder {
  color: var(--primary-color);
  opacity: 1;
}
::-moz-placeholder {
  color: var(--primary-color);
  opacity: 1;
}
:-moz-placeholder {
  color: var(--primary-color);
  opacity: 1;
}
:-ms-input-placeholder {
  color: var(--primary-color);
  opacity: 1;
}
:focus {
  outline: -webkit-focus-ring-color auto 0px;
}
/* body::-webkit-scrollbar {
  width: 20px;
} */
body::-webkit-scrollbar-track {
  background: #ffffff;
}
body::-webkit-scrollbar-thumb {
  background-color: #999999;
  outline: 1px solid transparent;
}

.seller_tab__pane {
  height: 250px;
}
.seller_tab__content {
  padding: 0px 20px 0px 20px;
}

/* Admin Sellers */

.admin.author__followers {
  flex-direction: row !important;
}

.admin.author__followers div svg {
  font-size: 15px;
  margin-right: 6px !important;
  margin-top: -2px;
  color: #fed7aa;
}

.author_admin_select {
  float: right;
  position: absolute;
  right: 38px;
  bottom: 21px;
}

.featured_box_indi {
  background-color: #fff;
  width: 8px;
  height: 8px;
  display: inline-block;
  border-radius: 50%;
  margin: 0px 8px;
}

.new-slider-feature .carousel.carousel-slider {
  overflow: visible;
}

.new-slider-feature .control-dots {
  bottom: -42px !important;
}
.pb-20-c {
  padding-bottom: 20px !important;
}
.w-250 {
  width: 250px;
}
.w-300 {
  width: 300px;
}
.pl-0 {
  padding-left: 0;
}
.hover\:text-white:hover {
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}

.reappeal_btn:hover {
  text-decoration: underline;
}
.text-gray-200 {
  --tw-text-opacity: 1;
  color: rgba(228, 228, 231, var(--tw-text-opacity));
}
.mt-0 {
  margin-top: 0 !important;
}

.mt-10 {
  margin-top: 2.5rem /* 40px */ !important;
}
.max-w-300-px {
  max-width: 300px;
}
.h-350-px {
  height: 350px;
}
.h-screen {
  height: 100vh;
}
.border-l-2 {
  border-left-width: 2px;
}
.border-b-2 {
  border-bottom-width: 2px;
}
.border-gray-400 {
  --tw-border-opacity: 1;
  border-color: rgba(161, 161, 170, var(--tw-border-opacity));
}
.border-gray-300 {
  --tw-border-opacity: 1;
  border-color: rgba(212, 212, 216, var(--tw-border-opacity));
}
.nft_image_skeleton-wrapper {
  min-height: 150px;
}
@media (min-width: 640px) {
  .sm\:flex-row {
    flex-direction: row;
  }
  .sm\:w-7\/12 {
    width: 58.333333%;
  }
  .sm\:h-screen {
    height: 100vh;
  }
  .sm\:border-l-2 {
    border-left-width: 2px;
  }
  .sm\:border-0 {
    border-width: 0px;
  }
}

@media (min-width: 768px) {
  .md\:w-3\/6 {
    width: 50%;
  }
}

@media (min-width: 1024px) {
  .lg\:w-3\/12 {
    width: 25%;
  }
  .lg\:max-w-300-px {
    max-width: 300px;
  }
}

/*  */
.steps-content-wrapper {
  display: flex;
  justify-content: space-between;
  padding-top: 20px;
}
.steps-content-wrapper .ant-steps-item-icon .ant-steps-icon {
  position: relative !important;
  top: -3px !important;
}
.ant-steps-item-process
  > .ant-steps-item-container
  > .ant-steps-item-icon
  .ant-steps-icon {
  position: relative !important;
  top: -1.5px !important;
}
.ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon {
  position: relative !important;
  top: -1.5px !important;
}

/* .ant-select-selection-overflow {
  overflow: hidden;
  height: 30px;
} */

.steps-content {
  width: 90%;
  margin-top: 5px;
  min-height: fit-content;
}
.steps-content-inner-wrapper {
  /* height: 400px; */
  /* min-height: 421px; */
  width: 100%;
  padding: 15px;
  background-color: #fafafa;
  border: 1px dashed #e9e9e9;
  border-radius: 16px;
}
.steps-content-inner-wrapper .sign__title {
  font-weight: 600;
  color: #ff9700;
}
.steps-content-wrapper .steps_content_list_box {
  margin-top: 50px;
}
.upload_wrapper {
  text-align: center;
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
  align-items: center !important;
}

.steps-action {
  margin-top: 24px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.mint_sign_form_custom {
  padding: 0 !important;
}

.collection_main_box {
  height: 273px;
}

/*  */
.widget_dialog_content {
  min-height: 300px;
  max-height: 500px;
  min-width: 500px;
  overflow-y: 'auto';
  padding: 0 !important;
}
.widget_content_iframe {
  height: 100%;
  width: 100%;
  min-height: 300px;
  max-height: 500px;
}

.footer_image_wrapper_wp img {
  width: 25%;
}

/* collection-nft-cards */

.nft_image_skeleton-wrapper {
  border-radius: 10px 10px 0px 0px;

  height: auto;
}
.nft_image_skeleton-wrapper .img_class_box {
  min-height: 100%;
  padding: 15px 15px 15px 15px;
  border-radius: 40px;
  max-height: 100%;
  object-fit: cover;
}
.coupon_input_box {
  border: 1px solid #e0e0e0;
}
.coupon_input_box:focus {
  outline: none !important;
  border: 1px solid #ff9700;
  --tw-ring-color: #ff9700 !important;
}
.bg-green-custom {
  background: #79c879;
}

/*  */
@media screen and (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}
.fade:not(.show) {
  opacity: 0;
}
.collapse:not(.show) {
  display: none;
}
.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.5s ease;
}
@media screen and (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}
:focus-visible {
  outline: 0px dotted;
}
.row--grid {
  margin-right: 0px;
  margin-left: 0px;
}
.row--grid .col-6,
.row--grid .col-12 {
  padding-left: 10px;
  padding-right: 10px;
}
@media (min-width: 768px) {
  .row--grid .col-6,
  .row--grid .col-12 {
    padding-left: 15px;
    padding-right: 15px;
  }
  .main_filter_input {
    width: 315px;
  }
}
.row--sidebar {
  margin-right: -10px;
  margin-left: -10px;
}
.row--sidebar .col-6,
.row--sidebar .col-12 {
  padding-left: 10px;
  padding-right: 10px;
}
.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}
.owl-carousel .owl-item {
  -webkit-transform: none;
}

.center_container {
  display: flex;
  justify-content: center;
}
/* Phone */
.container {
  max-width: calc(100% - 16px * 2);
  /* margin-left: 16px;
  margin-right: 16px; */
  margin: auto;

  padding: 30px 0;
  min-width: 250px;
}
.home_container {
  padding: 30px 0 !important;
}
.main {
  min-width: 250px;
}
/* Phone sm*/
@media (min-width: 576px) {
  .container {
    max-width: calc(100% - 16px * 2);
    /* margin-left: 16px;
    margin-right: 16px; */
    margin: auto;

    padding: 30px 0;
  }
  .home_container {
    padding: 30px 0 !important;
  }
  .collection_main_box_container {
    padding: 30px 0 !important;
  }
}
@media (min-width: 320px) {
  .card__title {
    width: 100% !important;
  }
}
@media (min-width: 350px) {
  .card__title {
    width: 100% !important;
  }
}
@media (min-width: 400px) {
  .card__title {
    width: 100% !important;
  }
}
/* Tablet md*/
@media (min-width: 768px) {
  .container {
    max-width: calc(100% - 32px * 2);
    /* margin-left: 32px;
    margin-right: 32px; */
    margin: auto;
    padding: 50px 0;
  }
  .home_container {
    padding: 25px 0 !important;
  }
  .collection_main_box_container {
    padding: 60px 0 !important;
  }
}
/* Notebook lg*/
@media (min-width: 1024px) {
  .container {
    max-width: calc(100% - 32px * 2);
    /* margin-left: 32px;
    margin-right: 32px; */
    margin: auto;
    padding: 60px 0;
  }
  .home_container {
    padding: 30px 0 !important;
  }
}
/* Desktop xl*/
@media (min-width: 1280px) {
  .container {
    max-width: calc(100% - 48px * 2);
    /* margin-left: 48px;
    margin-right: 48px; */
    margin: auto;

    padding: 60px 0;
  }
  .home_container {
    padding: 30px 0 !important;
  }
}
/* Desktop 2xl*/
@media (min-width: 1536px) {
  .xxl\:w-2\/12 {
    width: 20%;
  }
  .container {
    max-width: 1440px;
    /* margin-left: 48px;
    margin-right: 48px; */
    margin: auto;

    padding: 60px 0;
  }
  .home_container {
    padding: 30px 0 !important;
  }

  .order__center {
    max-width: 90%;
  }
}
/*==============================
  Header
==============================*/
.header {
  /* position:fixed; */
  position: sticky;
  /* top: 0; */
  left: 0;
  right: 0;
  width: auto;
  height: 70px;
  background-color: var(--primary-bg-color);
  border-bottom: 1px solid var(--border-color);
  z-index: 1001;
}
.header__content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 70px;
  width: 100%;
  position: relative;
}
.header__logo {
  display: inline-flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  height: 40px;
}
.header__logo img {
  width: auto;
  height: 40px;
  display: block;
  margin-right: 0px;
  background-image: '/img/brand/Logo_icon.png';
}
.header__actions {
  margin-left: 0px;
}

@media (min-width: 1024px) {
  .header__logo img {
    background-image: '/img/brand/Logo1.png';
  }
}
.displayname {
  white-space: nowrap;
  display: flex;
  align-items: center;
}
.header__actions {
  display: inline-flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  width: auto;
  margin-left: auto;
  margin-left: 0px;
}
.header__action {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: fit-content;
  position: relative;
  margin-left: 20px;
}
.header__action > button {
  padding-right: 0px;
}
.header__action:first-child {
  margin-left: 0;
}
.header__action--profile {
  width: auto;
}
.header__action-btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 36px;
  width: 100%;
}
.header__action-btn--signin {
  height: 40px;
  min-width: 100px;
}

.header__action-btn:hover svg {
  fill: var(--title-color);
}
.header__action-btn span {
  display: block;
  white-space: nowrap;
  color: var(--primary-color);
  font-size: 14px;
  margin-right: 10px;
  font-family: var(--head-font);
  font-weight: 400;
  transition: color 0.5s ease;
}
.header__action-btn--signin {
  background-color: var(--orange-color);
  border-radius: 12px;
  padding: 0 20px;
}
.header__action-btn--signin span {
  margin-right: 0;
  color: #fff;
}
.header__action-btn--signin svg {
  display: none;
}

.header__action-btn--signin:hover {
  background-color: var(--border-color);
}
.header__action-btn--signin:hover span {
  color: var(--orange-color);
}
.header__btn {
  position: absolute;
  width: 22px;
  height: 22px;
  display: block;
  right: 15px;
  top: 24px;
}
.header__btn span {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  right: 0;
  width: 22px;
  height: 2px;
  background-color: var(--primary-color);
  border-radius: 2px;
  transition: 0.5s ease;
  transition-property: width, background-color;
}
.header__btn span:first-child {
  top: 0;
}
.header__btn span:nth-child(2) {
  top: 10px;
  width: 16px;
}
.header__btn span:last-child {
  top: 20px;
  width: 10px;
}
.header__btn:hover span {
  background-color: var(--orange-color);
}
.header__btn--active span {
  background-color: var(--orange-color);
}
.header__btn--active span:nth-child(2) {
  width: 22px;
}
.header__btn--active span:last-child {
  width: 22px;
}
.header__search {
  position: absolute;
  left: 0;
  top: -71px;
  width: 100%;
  height: 70px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  background-color: var(--primary-bg-color);
  z-index: 1;
  padding: 0 15px;
  border-bottom: 1px solid var(--border-color);
  transition: top 0.5s ease;
}
.header__search input {
  width: calc(100% - 30px);
  height: 40px;
  background-color: var(--secondary-bg-color);
  color: var(--title-color);
  font-size: 14px;
  border-radius: 12px;
  border: none;
  padding: 0 45px 0 20px;
  font-family: var(--text-font);
  font-weight: 400;
}
.header__search input:focus {
  border-color: var(--title-color);
}
.header__search button {
  position: absolute;
  right: 60px;
  top: 15px;
  height: 40px;
  width: auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.header__search button svg {
  width: 20px;
  height: auto;
  fill: #bdbdbd;
  transition: 0.5s ease;
}
.header__search button:hover svg {
  fill: var(--orange-color);
}
.header__search button.close {
  right: 15px;
}
.header__search--active {
  top: 0;
}

.header__search .show {
  display: flex;
}

.header__search .hide {
  display: none;
}

.header__menu {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  position: fixed;
  top: 70px;
  right: 0;
  bottom: 0;
  background-color: #16151a;
  z-index: 99;
  width: 280px;
  padding: 25px 25px 0;
  /* margin-left: 70px; */
  transform: translate3d(281px, 0, 0);
  transition: transform 0.5s ease;
  border-left: 1px solid var(--border-color);
}
.header__menu--active {
  transform: translate3d(0, 0, 0);
}
.header__nav {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  position: relative;
}
.header__nav-item {
  margin-bottom: 20px;
  position: relative;
}
.header__nav-item:last-child {
  margin-bottom: 0;
}
.header__nav-link {
  font-size: 14px;
  color: var(--black-color);
  line-height: 22px;
  height: 100%;
  display: inline-flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  font-weight: 400;
  font-family: var(--head-font);
  padding-left: 20px;
  padding-right: 20px;
}
.header__nav-link:hover,
.menu-item:hover {
  color: var(--orange-color) !important;
  font-weight: 600;
  border-bottom: none;
}
.menu-item {
  transition: color 0.5s !important;
}
.active_nav_link {
  color: var(--button-bg-color) !important;
  font-weight: 600;
  border-bottom: none;
}
.header__nav-link svg {
  fill: #bdbdbd;
  width: 14px;
  height: auto;
  transition: fill 0.5s ease;
  margin-left: 1px;
  margin-top: 2px;
}
.header__nav-link--menu svg {
  width: 20px;
  margin-top: 0;
  margin-left: 0;
}
.header__nav-link--active {
  color: var(--orange-color);
  cursor: default;
  font-weight: 500;
}
.header__nav-link--active:hover {
  color: var(--orange-color) !important;
}
.header__nav-link:hover,
.header__nav-link[aria-expanded='true'] {
  color: var(--title-color);
}
.header__nav-link:hover svg,
.header__nav-link[aria-expanded='true'] svg {
  fill: var(--orange-color);
}
.header__nav-menu {
  display: block;
  position: absolute !important;
  z-index: -1;
  pointer-events: none;
  opacity: 0;
  top: 0;
  background-color: #16151a;
  border-radius: 16px;
  padding: 0 20px;
  width: 200px;
  transition: opacity 0.5s ease;
  transform: translate3d(0px, 22px, 0px);
  margin-top: 10px;
  height: auto;
  border: 1px solid var(--border-color);
}
.header__nav-menu .header__nav-menu {
  margin-top: 5px;
  margin-left: 20px;
}
.header__nav-menu li {
  position: relative;
  margin-bottom: 15px;
}
.header__nav-menu li:first-child {
  padding-top: 20px;
}
.header__nav-menu li:last-child {
  margin-bottom: 0;
  padding-bottom: 20px;
}
.header__nav-menu a {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  color: var(--primary-color);
  font-size: 14px;
  font-weight: 400;
  transition: color 0.5s ease;
  position: relative;
  font-family: var(--head-font);
}
.header__nav-menu a svg {
  fill: #bdbdbd;
  width: 14px;
  height: auto;
  transition: 0.5s ease;
  margin-left: 1px;
  margin-top: 2px;
}
.header__nav-menu a:hover,
.header__nav-menu a[aria-expanded='true'] {
  color: var(--title-color);
}
.header__nav-menu a:hover svg,
.header__nav-menu a[aria-expanded='true'] svg {
  fill: var(--orange-color);
}
.header__nav-menu.show {
  z-index: 1000;
  pointer-events: auto;
  opacity: 1;
}
.header__profile-btn {
  overflow: hidden;
  padding: 10px 5px;
  /* display: inline-flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%; */
  /* position: relative;
  padding: 0 6px 0 6px;
  height: 45px;
  font-family: var(--head-font);
  border: 1px solid var(--border-color);
  border-radius: 16px; */
}
.header__profile-btn--verified:after {
  content: '';
  position: absolute;
  display: none;
  width: 15px;
  height: 15px;
  bottom: 5px;
  left: 30px;
  border-radius: 50%;
  border: 1px solid #ffffff;

  background: url('/img/musicart-symbol.png') no-repeat center
    var(--orange-color);
  background-size: 14px auto;
  z-index: 1;
  pointer-events: none;
}
.header__profile-btn img {
  display: block;
  width: 30px !important;
  height: 30px;
  border-radius: 8px;
  min-width: 30px;
  margin-right: 6px;
}
.header__profile-btn div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-left: 6px;
}
.header__profile-btn p {
  margin-bottom: 0;
  line-height: 22px;
  font-size: 14px;
  color: var(--title-color);
  font-family: var(--head-font);
  font-weight: 500;
}
.header__profile-btn span {
  font-size: 12px;
  color: var(--primary-color);
  line-height: 18px;
}
.header__profile-btn svg {
  fill: #bdbdbd;
  width: 16px;
  height: auto;
  transition: fill 0.5s ease;
  margin-left: 6px;
  margin-top: 2px;
}
.header__profile-btn:hover {
  border-color: var(--orange-color);
}
.header__profile-btn:hover svg,
.header__profile-btn[aria-expanded='true'] svg {
  fill: var(--orange-color);
}
.header__profile-menu {
  display: block;
  position: absolute !important;
  z-index: -1;
  pointer-events: none;
  opacity: 0;
  top: 0;
  background-color: #16151a;
  border-radius: 16px;
  padding: 20px;
  min-width: 180px;
  transition: opacity 0.5s ease;
  transform: translate3d(0px, 54px, 0px) !important;
  height: auto;
  border: 1px solid var(--border-color);
  right: 0 !important;
  left: auto !important;
  margin-top: 2px;
}
.header__profile-menu li {
  margin-bottom: 15px;
  width: 100%;
}
.header__profile-menu li:last-child {
  margin-bottom: 0;
}
.header__profile-menu li:last-child {
  padding-top: 15px;
  border-top: 1px solid var(--border-color);
}
.header__profile-menu a {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  color: var(--primary-color);
  font-size: 14px;
  font-weight: 400;
  transition: color 0.5s ease;
  position: relative;
  font-family: var(--head-font);
}
.header__profile-menu a svg {
  fill: var(--title-color);
  width: 20px;
  height: auto;
  transition: fill 0.5s ease;
  margin-right: 10px;
}
.header__profile-menu a:hover {
  color: var(--title-color);
}
.header__profile-menu a:hover svg {
  fill: var(--orange-color);
}
.header__profile-menu.show {
  z-index: 1000;
  pointer-events: auto;
  opacity: 1;
}

.owner__history {
  margin: 100px 50px 100px 50px;
  border: 1px solid var(--border-color);
}
.owner__history h1 {
  font-size: 24px;
  font-weight: 700;
  padding: 15px 20px 15px 20px;
}
@media (min-width: 576px) {
  .header__action--signin {
    width: auto;
    padding-left: 22px;
  }
  .header__action--signin:before {
    content: '';
    position: absolute;
    display: block;
    width: 1px;
    height: 24px;
    background-color: var(--border-color);
    top: 50%;
    left: 0;
    margin-top: -11px;
  }
  .header__action--signin:hover a span,
  .header__action--signin:hover button span {
    color: var(--title-color);
  }
  .header__action--signin:hover a svg,
  .header__action--signin:hover button svg {
    fill: var(--orange-color);
  }
  .header__search {
    padding: 0 30px;
  }
  .header__search button {
    right: 75px;
  }
  .header__search button.close {
    right: 30px;
  }
  .header__btn {
    right: 30px;
  }
  .header__profile-btn img {
    display: block;
  }
  .header__profile-btn--verified:after {
    display: block;
  }
}
@media (min-width: 768px) {
  .header__action {
    margin-left: 30px;
  }
  .header__action--signin {
    padding-left: 32px;
  }
  .header__actions {
    margin-right: 0px;
  }
}
@media (min-width: 1200px) {
  .header__logo {
    width: auto;
    margin-right: 0px;
  }
  .header__btn {
    display: none;
  }
  .header__actions {
    margin-right: 0;
  }
  .header__action--search {
    display: none;
  }
  .header__action--signin {
    padding-left: 0;
  }
  .header__action--signin:before {
    display: none;
  }

  .header__search {
    position: relative;
    top: auto;
    left: auto;
    width: 280px;
    padding: 0;
    border: none;
    background-color: transparent;
  }
  .header__search .hide {
    display: none;
  }
  .header__search input {
    padding: 0 60px 0 20px;
    width: 100%;
  }
  .header__search button {
    right: 20px;
  }
  .header__search button.close {
    display: none;
  }
  .header__search--active {
    top: auto;
  }
  .header__menu {
    flex-direction: row;
    align-items: center;
    width: auto;
    padding: 0;
    position: relative;
    top: auto;
    right: auto;
    bottom: auto;
    background-color: transparent;
    transform: translate3d(0, 0, 0);
    border: none;
    transition: transform 0s ease;
  }
  .header__nav {
    flex-direction: row;
    align-items: center;
    width: auto;
  }
  .header__nav-item {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    height: 70px;
    margin-bottom: 0;
    /* margin-left: 50px; */
  }
  .header__nav-menu {
    transform: translate3d(0px, 46px, 0px);
  }
  .header__nav-menu .header__nav-menu {
    transform: translate3d(0px, 22px, 0px);
  }
}
@media (min-width: 1440px) {
  .header__search {
    width: 360px;
  }
  .footer_email {
    width: 360px;
  }
  header__nav-item {
    margin-left: 90px;
  }
}
/*==============================
  Main
==============================*/
.main {
  position: relative;
  /* margin-top: 70px; */
}
.main__title {
  display: flex;
  color: var(--title-color);
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  flex-wrap: wrap;
  font-weight: 600;
  margin-bottom: 10px;
}

.main__title--clr {
  color: var(white);
}
.main__title--center {
  justify-content: center;
}
.main__title h1 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  font-family: var(--head-font);
  font-weight: 600;
  font-size: 30px;
  line-height: 140%;
  margin-bottom: 0;
  position: relative;
}
.main__title h1 b {
  font-weight: 800;
}
.main__title h1 a:hover {
  color: #ff9700;
}

.main__title h2 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  /* color: #ffffff; */
  font-family: var(--head-font);
  font-weight: 600;
  font-size: 26px;
  line-height: 150%;
  margin-bottom: 0;
  position: relative;
}
.title_white {
  color: #ffffff !important;
  text-align: center;
  margin-left: 5px;
  margin-right: 5px;
}

.main__title h2 svg {
  width: 26px;
  height: auto;
  fill: #ff9700;
  margin-right: 10px;
}
.main__title h2 b {
  font-weight: 800;
}
.main__title h2 a:hover {
  color: #ff9700;
}
.main__title h3 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  font-family: var(--head-font);
  font-weight: 600;
  font-size: 24px;
  margin-bottom: 0;
  line-height: 100%;
  position: relative;
}
.main__title p {
  display: block;
  width: 100%;
  font-size: 16px;
  line-height: 26px;
  color: var(--primary-color);
  margin-top: 20px;
  margin-bottom: 0;
}
.main__title p b {
  font-weight: 800;
}
.main__title p a {
  color: #ff9700;
  text-decoration: underline;
}
.main__title p a:hover {
  text-decoration: none;
}
.main__title ol {
  padding-left: 0;
  list-style: none;
  counter-reset: li;
  margin-bottom: 0;
}
.main__title ol ol {
  padding-left: 15px;
  margin-top: 10px;
}
.main__title ol ol ol {
  margin-top: 10px;
  margin-bottom: 10px;
}
.main__title ol ol ol li {
  margin-top: 5px;
}
.main__title ol h4 {
  font-size: 16px;
  color: var(--title-color);
  display: inline-block;
  margin-bottom: 0;
  margin-top: 20px;
  font-weight: 800;
  font-family: var(--head-font);
}
.main__title ol li {
  font-size: 16px;
  line-height: 24px;
  color: var(--primary-color);
  position: relative;
}
.main__title ol li b {
  font-weight: 800;
  color: var(--primary-color);
}
.main__title ol li a {
  color: #eb5757;
}
.main__title ol li a:hover {
  color: #eb5757;
  text-decoration: underline;
}
.main__title ol li:last-child {
  margin-bottom: 0;
}
.main__title ol li:before {
  counter-increment: li;
  content: counters(li, '.') '. ';
}
.main__title--page {
  margin-bottom: 0;
  margin-top: 40px;
}
.main__title--border-top {
  padding-top: 55px;
  border-top: 1px solid var(--border-color);
}
.main__title--center {
  justify-content: center;
  align-items: center;
}
.main__title--center h1,
.main__title--center h2 {
  text-align: center;
  justify-content: center;
}
.main__link {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  color: var(--primary-color);
  font-weight: 600;
  margin-bottom: 5px;
}
.main__link svg {
  width: 22px;
  height: auto;
  fill: #bdbdbd;
  margin-left: 5px;
  transition: fill 0.5s ease;
  margin-right: -3px;
}
.main__link:hover {
  color: var(--title-color);
}
.main__link:hover svg {
  fill: #ff9700;
}
.main__carousel-wrap {
  position: relative;
}
.main__carousel .owl-dots {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 25px;
  position: relative;
}
.main__carousel .owl-dot {
  margin-right: 10px;
}
.main__carousel .owl-dot:last-child {
  margin-right: 0;
}
.main__carousel .owl-dot span {
  display: block;
  height: 4px;
  width: 10px;
  border-radius: 2px;
  background-color: var(--primary-color);
  transition: 0.5s ease;
}
.main__carousel .owl-dot.active span {
  width: 20px;
  background-color: #ff9700;
}
.main__nav {
  display: none;
}
.auth_bg {
  background: linear-gradient(90deg, #f8b80b, #f16604);
}
.main__filter {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  margin-top: 20px;
  padding: 20px 20px 10px 20px;
  position: relative;
  border-radius: 16px;
  border: 1px solid var(--secondary-bg-color);
  background-color: var(--primary-bg-color);
  box-shadow: 0px 0px 15px 0px rgb(0 11 40 / 20%);
}
.main__filter_child {
  padding-top: 10px;
  padding-bottom: 10px;
}
.main__filter_btns {
  width: 100%;
}
.main__filter-wrap {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  z-index: 2;
  width: 100%;
  margin-bottom: 10px;
}
.filter-wrap-mr-30 {
  margin-right: 0px;
}
.main__filter-search {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  margin-bottom: 10px;
  outline: none;
  border: 0;
}
.main__filter-search input {
  width: 100%;
  height: 46px;
  background-color: var(--border-color);
  color: var(--title-color);
  font-family: var(--head-font);
  font-size: 16px;
  border-radius: 12px;
  border: none;
  outline: none;
  padding: 0 60px 0 20px;
}
.main__filter-search input:focus {
  border: 2px solid #ff9700 !important;
  outline: none !important;
  box-shadow: unset;
  outline-offset: 0px !important;
  transition: 0s !important;
}
.main__filter-search button {
  position: absolute;
  right: 20px;
  top: 0;
  height: 46px;
  width: auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.main__filter-search button svg {
  width: 20px;
  height: auto;
  fill: #bdbdbd;
  transition: 0.5s ease;
}
.main__filter-search button:hover svg {
  fill: #ff9700;
}
.featured_nft_ft {
  margin-left: 12px;
  margin-bottom: 23px;
}
.featured_nft_ft input:focus {
  border: 0 !important;
  outline: none !important;
  box-shadow: none;
}
.featured_nft_ft-select {
  width: 200px;
  margin: 12px 0px;
}
.helper_text {
  position: relative;
  padding: 0px 10px;
  font-size: 10px;
  top: -10px;
}
.close_icon {
  position: absolute;
  right: 22px;
  /* top: 0; */
  height: 46px;
  width: auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: transparent !important;
  padding: 0px !important;
}
.close_icon svg {
  width: 20px;
  height: auto;
  fill: #bdbdbd;
  transition: 0.5s ease;
  /* margin-right: 15px; */
}
.close_icon_scroll::-webkit-scrollbar {
  display: none !important;
}
.close_icon:hover svg {
  fill: #ff9700;
}

.close_icon_icon {
  position: absolute;
  right: 15px;
  top: 0;
  height: 46px;
  width: auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: transparent !important;
  padding: 0px !important;
}
.close_icon_icon svg {
  width: 20px;
  height: auto;
  fill: #bdbdbd;
  transition: 0.5s ease;
}
.close_icon_icon:hover svg {
  fill: #ff9700;
}

.filter__select-wrap {
  position: relative;
  width: 100%;
}
select option {
  height: 20px;
}
.antSelect_opt {
  height: 20px;
}
.collection_select {
  width: 100%;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-width: 0px !important;
  border-radius: 12px;
}
.multi_select_same_ui .ant-select-selector {
  background-color: var(--border-color) !important;
  border-radius: 12px !important;
  border-color: transparent !important;
  box-shadow: none !important;
}

.ant-select-focused {
  border: 2px solid #ff9700 !important;
  border-radius: 12px !important;
}

/* .ant-select:not(.ant-select-disabled):hover .ant-select-selector {
  border: 2px solid #ff9700 !important;
} */
.ant-select-selection-placeholder {
  color: #71717a;
  margin-left: 10px;
}
td.ant-table-column-sort {
  background: transparent !important;
}
.ant-table-cell-row-hover {
  background: transparent !important;
}
.table-row-red {
  background-color: red !important;
}
.table-row-red:hover {
  background-color: red !important;
}
/* .ant-select:not(.ant-select-disabled):hover .ant-select-selector {
  border-color: transparent !important;
} */

.multi_select_same_ui .ant-select-selector,
.main__select {
  background: url('/img/arrow2.svg') no-repeat center right 20px
    var(--border-color);
  background-size: 18px auto;
  border: 1px solid transparent;
  border-radius: 12px;
  height: 46px;
  position: relative;
  color: var(--title-color);
  font-family: var(--head-font);
  font-size: 14px;
  width: 100%;
  padding: 0 14px;
  cursor: pointer !important;
}
.main__select:focus {
  border-color: #ff9700;
  --tw-ring-color: #4b463e !important;
}

.main__load {
  width: 160px;
  height: 50px;
  border-radius: 12px;
  background-color: #ff9700;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: var(--primary-bg-color);
  font-size: 14px;
  position: relative;
  z-index: 2;
  text-transform: uppercase;
  font-family: var(--text-font);
  font-weight: 500;
  margin: 30px auto 0;
  transition: 0.5s ease;
  transition-property: color, background-color, border-color, box-shadow,
    transform, margin-top, height;
}
.main__load:hover {
  color: #ff9700;
  background-color: white;
  border: #ff9700 1.5px solid;
}
.main__load[aria-expanded='true'] {
  margin-top: 0;
  transform: scale(0);
  height: 1px;
}
.main__tabs {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  width: 100%;
  margin-top: 10px;
}
.main__tabs li {
  margin-top: 15px;
  margin-right: 15px;
}
.main__tabs li:last-child {
  margin-right: 0;
}
.main__tabs a {
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: auto;
  color: var(--primary-color);
  font-size: 16px;
  background-color: var(--border-color);
  border-radius: 12px;
  padding: 0 15px;
  height: 32px;
}
.main__tabs a:hover {
  color: var(--title-color);
}
.main__tabs a.active {
  color: var(--title-color);
  background-color: #ff9700;
}
.main__author {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 200px;
  z-index: 1;
  background: linear-gradient(
    90deg,
    rgba(246, 154, 7, 1) 0%,
    rgba(241, 102, 4, 1) 100%
  );
  background-position: contain;
  background-size: cover;
  background-repeat: no-repeat;
}
.intro__image {
  align-self: center;
  margin: auto;
}
.about__bg {
  background: linear-gradient(90deg, #f8b80b, #f16604);
}
.aboutPg__bg {
  background: linear-gradient(90deg, #f8b80b, #f16604);
}
.intro__text {
  color: black;
  font-family: var(--text-font);
  font-size: 16px;
  font-weight: 400;
  align-self: center;
}
.about__text h1 {
  color: black;
  font-family: var(--head-font);
  font-size: 30px;
  font-weight: 600;
  text-transform: uppercase;
  padding: 16px 0px 16px 0px;
}
.about__text h3 {
  color: black;
  font-family: var(--head-font);
  font-size: 20px;
  font-weight: 600;
  text-transform: uppercase;
  padding: 16px 0px 16px 0px;
}
.about__text p {
  color: black;
  font-family: var(--text-font);
  font-size: 16px;
  font-weight: 400;
  padding: 16px 0px 16px 0px;
}

.about__text div.wrap-line {
  color: black;
  margin: 10px 0 70px 0;
}

.about_links p {
  color: black;
  font-size: 24px;
  font-weight: 600;
  padding: 16px 0px 16px 0px;
}
.about_links a {
  color: rgb(255, 255, 255);
  font-size: 50px;
  margin: 30px 30px 30px 30px;
}
.viewmore__link {
  margin-top: 20px;
  text-align: right;
}
.contact_form-wrapper {
  width: 66.6%;
  margin: auto;
}
@media (min-width: 768px) {
  .main__title {
    /* margin-top: 70px; */
    margin-bottom: 0;
  }
  .main__title h1 {
    font-size: 36px;
  }
  .main__title h2 {
    font-size: 30px;
  }
  .main__title h2 svg {
    width: 30px;
  }
  .main__title p:last-child {
    margin-bottom: 10px;
  }
  .main__title--sidebar {
    margin-bottom: 10px;
  }
  .main__title--border-top {
    padding-top: 65px;
  }
  .main__title--page {
    margin-top: 30px;
  }
  .main__carousel .owl-dots {
    margin-top: 30px;
  }
  .main__filter {
    padding: 0 20px;
    flex-direction: row;
    align-items: center;
    margin-top: 30px;
    /* height: 70px; */
    flex-wrap: wrap;
  }
  .main__filter_btns {
    width: 200px;
  }
  .main__filter-search {
    margin-bottom: 0;
    margin-right: 30px;
    width: 350px;
  }
  .main-filter-input {
    width: 310px;
  }
  .main__filter-search input {
    width: 100%;
  }
  .main__filter-wrap {
    width: auto;
    margin-bottom: 0px;
  }
  .filter-wrap-mr-30 {
    margin-right: 30px;
  }
  .main__select {
    width: 260px;
  }

  .main__load {
    margin: 40px auto 0;
  }
  .main__tabs li {
    margin-top: 20px;
    margin-right: 20px;
  }
  .main__tabs li:last-child {
    margin-right: 0;
  }
  .main__video-bg {
    margin-top: 70px;
    padding-bottom: 70px;
  }
  .main__video {
    margin-top: 40px;
  }
  .main__author {
    height: 300px;
  }
}
@media (min-width: 992px) {
  .main__title--sidebar {
    margin-top: 30px;
  }
  .main__filter-search {
    margin-bottom: 0;
  }
  .main__filter-search input {
    width: 260px;
  }
  .main__select {
    width: 260px;
  }
}
@media (min-width: 1024px) {
  .intro__text {
    margin-left: 70px;
  }
}
@media (min-width: 1200px) {
  .main__nav {
    position: absolute;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 34px;
    height: 34px;
    right: 0;
    top: -35px;
    z-index: 2;
  }
  .main__nav svg {
    width: 30px;
    height: auto;
    fill: #bdbdbd;
    transition: fill 0.5s ease;
  }
  .main__nav:hover svg {
    fill: #ff9700;
  }
  .main__nav--prev {
    right: 25px;
  }
  .main__nav--next {
    right: -5px;
  }
  .main__nav--hero {
    top: auto;
    bottom: 25px;
  }
  .main__nav--hero svg {
    fill: var(--title-color);
  }
  .main__nav--hero.main__nav--prev {
    right: 95px;
  }
  .main__nav--hero.main__nav--next {
    right: 65px;
  }
  .main__filter-search {
    margin-bottom: 0;
  }
  .main__filter-search input {
    width: 260px;
  }
  .main__select {
    width: 260px;
  }

  .main__title--mint {
    margin-top: 30px;
  }
}
@media (min-width: 1440px) {
  .main__filter-search {
    margin-bottom: 0;
  }
  .main__filter-search input {
    width: 260px;
  }
  .main__select {
    width: 260px;
  }
}
/*==============================
  Breadcrumb
==============================*/
.breadcrumb {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  margin-top: 5px;
  margin-left: 1rem;
}

.breadcrumb__item {
  font-size: 14px;
  color: var(--primary-color);
  transition: 0.5s ease;
  position: relative;
  margin-right: 34px;
}
.breadcrumb__item:before {
  content: '';
  position: absolute;
  left: 100%;
  top: 1px;
  bottom: 0;
  width: 34px;
  background: url('/img/breadcrumb.svg') no-repeat center/16px auto;
}
/* .breadcrumb__item:hover {
  color: white;
} */
.breadcrumb__item:last-child {
  margin-right: 0;
}
.breadcrumb__item:last-child:before {
  display: none;
}
.breadcrumb__item--active:before {
  display: none;
}
.main_wrapper .breadcrumb__item--active {
  color: var(--primary-color);
}
.breadcrumb__item--active {
  color: #ff9700;
}
.breadcrumb a {
  color: var(--primary-color);
}
.breadcrumb a:hover {
  color: #ff9700;
}
.breadcrumb__item .crumb_item:hover {
  color: #fff;
}

.breadcrumb .yellow__back {
  color: var(--primary-color);
}

.breadcrumb .yellow__back--active {
  color: var(--button-color);
}

.breadcrumb .yellow__back a:hover {
  color: var(--button-color);
}

/*==============================
  Filter
==============================*/
.filter-wrap {
  margin-top: 20px;
  height: 100%;
}
.filter-wrap__btn {
  width: 100%;
  height: 50px;
  border-radius: 12px;
  background-color: var(--border-color);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: var(--title-color);
  font-size: 14px;
  position: relative;
  z-index: 2;
  text-transform: uppercase;
  font-family: var(--head-font);
  font-weight: 500;
}
.filter-wrap__btn:hover {
  color: var(--title-color);
  background-color: var(--border-color);
}
@media (min-width: 768px) {
  .filter-wrap {
    margin-top: 30px;
  }
}
@media (min-width: 1200px) {
  .filter-wrap {
    margin-top: 0;
  }
  .filter-wrap__btn {
    display: none;
  }
  .filter-wrap__content {
    display: block !important;
    position: relative;
    height: 100%;
  }
}
.filter {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 20px;
}
.filter__title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: auto;
  width: 100%;
  color: var(--title-color);
  font-family: var(--head-font);
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 25px;
}
.filter__title button {
  font-size: 12px;
  font-weight: 400;
  color: #ff9700;
}
.filter__title button:hover {
  color: #eb5757;
}
.filter__group-box {
  display: flex;
  flex-direction: row;
  grid-gap: 10px;
  width: 100%;
}

.filter_group_accordion_box {
  background-color: #e0e0e0 !important;
  color: var(--title-color);
  font-family: var(--head-font);
  font-size: 16px;
}
.filter_group_accordion_box:focus {
  border: 1px solid #ff9700;
}
.MuiInputBase-input:focus {
  --tw-ring-color: transparent;
}
.filter_box_title {
  font-weight: 400 !important;
  font-size: 14px !important;
  color: var(--primary-color) !important;
  font-family: var(--head-font) !important;
}

.filter_autocomplete .MuiInputBase-input:focus {
  --tw-ring-color: transparent;
}
.collection_filter_list_box {
  max-height: 180px;
  overflow-y: auto;
}
.filter_clear_btn_btn {
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 14.5px;
  font-weight: 400;
}
.filter_clear_btn_btn:hover {
  color: #ff9700;
}

.filter_chip_chip {
  box-shadow: unset !important;
  padding: 10px 15px 10px 15px !important;
  margin: 10px calc(var(--gutter) / 2) 0px calc(var(--gutter) / 2) !important;
  grid-gap: 8px !important;
}
.chip_box {
  font-family: Poppins, sans-serif !important;
}
.filter__group {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  margin-bottom: 20px;
  position: relative;
}
.filter__group:last-child {
  margin-bottom: 0;
}
.filter__label {
  line-height: 100%;
  font-weight: 400;
  font-size: 14px;
  color: var(--primary-color);
  margin-bottom: 15px;
  font-family: var(--head-font);
}
.filter__label b {
  font-weight: 500;
  color: var(--title-color);
}
.filter__select-wrap {
  position: relative;
  width: 100%;
}
.filter__select {
  background: url('/img/arrow2.svg') no-repeat center right 20px
    var(--border-color);
  background-size: 18px auto;
  border: 1px solid transparent;
  border-radius: 12px;
  height: 46px;
  position: relative;
  color: var(--title-color);
  font-size: 14px;
  width: 100%;
  padding: 0 20px;
  cursor: pointer;
  font-family: var(--head-font);
}
.filter__select:focus {
  border-color: #ff9700;
}
.filter__input {
  background-color: var(--border-color);
  border: 1px solid transparent;
  border-radius: 12px;
  height: 46px;
  position: relative;
  color: var(--title-color);
  font-size: 14px;
  width: 100%;
  padding: 0 20px;
  font-family: var(--head-font);
}
.filter__input:focus {
  border-color: #ff9700;
}
.filter__checkboxes {
  position: relative;
  margin-top: 5px;
}
.filter__checkboxes:first-child {
  margin-top: 0;
}
.filter__checkboxes li {
  position: relative;
  margin-bottom: 15px;
}
.filter__checkboxes li:last-child {
  margin-bottom: 0;
}
.filter__checkboxes input:not(:checked),
.filter__checkboxes input:checked {
  position: absolute;
  left: -9999px;
}
.filter__checkboxes input:not(:checked) + label,
.filter__checkboxes input:checked + label {
  font-size: 14px;
  font-weight: 400;
  position: relative;
  cursor: pointer;
  padding-left: 36px;
  line-height: 24px;
  font-family: var(--head-font);
  margin: 0;
  transition: color 0.5s ease;
}
.filter__checkboxes input:not(:checked) + label a,
.filter__checkboxes input:checked + label a {
  color: #ff9700;
}
.filter__checkboxes input:not(:checked) + label a:hover,
.filter__checkboxes input:checked + label a:hover {
  color: #ff9700;
  text-decoration: underline;
}
.filter__checkboxes input:not(:checked) + label {
  color: var(--primary-color);
}
.filter__checkboxes input:checked + label {
  color: var(--title-color);
}
.filter__checkboxes input:not(:checked) + label:before,
.filter__checkboxes input:checked + label:before {
  content: '';
  position: absolute;
  display: block;
  left: 0;
  top: 0;
  width: 24px;
  height: 24px;
  background-color: var(--border-color);
  border-radius: 8px;
}
.filter__checkboxes input:not(:checked) + label:after,
.filter__checkboxes input:checked + label:after {
  content: '';
  position: absolute;
  display: block;
  left: 0;
  top: 0;
  width: 24px;
  height: 24px;
  transition: 0.5s ease;
  background-color: var(--orange-color);
  border-radius: 10px;
  border: 6px solid var(--border-color);
}
.filter__checkboxes input:not(:checked) + label:after {
  opacity: 0;
  transform: scale(0);
}
.filter__checkboxes input:checked + label:after {
  opacity: 1;
  transform: scale(1);
}
.filter__checkboxes label::-moz-selection {
  background: transparent;
  color: var(--primary-color);
}
.filter__checkboxes label::selection {
  background: transparent;
  color: var(--primary-color);
}

.filter__btn {
  width: 100%;
  height: 50px;
  border-radius: 12px;
  color: var(--title-color);
  background-color: var(--button-bg-color-hover);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  position: relative;
  z-index: 2;
  font-family: var(--head-font);
  font-weight: 500;
}
/* .filter__btn:hover {
  color: #ffffff;
  background-color: #ff9700;
} */
.filter_btn_active {
  color: #ffffff;
  background-color: #ff9700;
}
.filter__keywords {
  position: relative;
  width: 100%;

  background-color: transparent;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  z-index: 1;
  padding: 0;
  border: none;
  transition: top 0.5s ease;
}
.discover_bar {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: auto;
  width: 100%;
  padding: 0px 15px;
}
.discover_sortby {
  width: 100%;
}
.filter__keywords input {
  width: 100%;
  height: 90px;
  background-color: transparent;
  color: var(--title-color);
  font-size: 20px;
  border-bottom: 1px solid var(--border-color);
  margin: 0px 0px 20px 0px;
  padding: 30px 60px 0 20px;
  font-family: var(--head-font);
  font-weight: 400;
}
.filter__keywords input:focus {
  border-bottom: 1px solid var(--title-color);
}
.filter__keywords button {
  position: absolute;
  right: 20px;
  height: 40px;
  width: 40px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  border-radius: 10px;
  color: white;
  background-color: #ff9700;
  align-items: center;
}
.filter__keywords button svg {
  width: 30px;
  height: auto;
  color: #fff;
  transition: 0.5s ease;
}
.filter__keywords button:hover svg {
  color: black;
}
.mint__btn {
  width: 200px;
  height: 50px;
  border-radius: 12px;
  background-color: #ff9700;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: var(--button-color);
  font-size: 14px;
  position: relative;
  z-index: 2;
  text-transform: uppercase;
  margin: 50px auto 30px auto;
  font-family: var(--head-font);
  font-weight: 500;
}
.mint__btn:hover {
  color: #ff9700;
  background-color: white;
  border: #ff9700 1px solid;
}
.mint__btn_panel {
  width: 100%;
  height: auto;
}
.mint__cover {
  width: 100%;
  height: 100%;
  border-radius: 16px;
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 15px 0px 15px 0px;
}
.nft__type {
  justify-content: center;
  align-items: center;
  text-align: center;
  border: 1px solid var(--border-color);
  padding: 20px 20px 20px 20px;
  margin: 20px 20px 20px 20px;
  border-radius: 10px;
}
.mint__title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  flex-wrap: wrap;

  /* margin-bottom: 10px; */
  /* margin-top: 60px; */
}
/* .mint_title_bg{
  background: linear-gradient(90deg, #f8b80b, #f16604);

} */

.main_wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 200px;
  z-index: 1;
  background: linear-gradient(
    90deg,
    rgba(246, 154, 7, 1) 0%,
    rgba(241, 102, 4, 1) 100%
  );
}
.contact_header_wrapper {
  display: flex;
  /* justify-content:flex-end; */
  align-items: flex-end;
  height: 150px;
  z-index: 1;
  background: linear-gradient(
    90deg,
    rgba(246, 154, 7, 1) 0%,
    rgba(241, 102, 4, 1) 100%
  );
}
.mint__title--center {
  justify-content: center;
}
.mint__title h1 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  font-family: var(--head-font);
  font-weight: 400;
  color: var(--title-color);
  font-size: 30px;
  line-height: 140%;
  margin-bottom: 0;
  position: relative;
}
.mint__title h1 b {
  font-weight: 600;
}
.mint__title h1 a {
  color: var(--title-color);
}
.mint__title h1 a:hover {
  color: #ff9700;
}
.mint__title h2 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  font-family: var(--head-font);
  font-weight: 600;
  color: var(--primary-bg-color);
  font-size: 36px;
  line-height: 150%;
  margin-bottom: 0;
  position: relative;
}
.mint__title h2 b {
  font-weight: 600;
}
.mint__title h2 a {
  color: var(--title-color);
}
.mint__title h4 {
  color: var(--primary-bg-color);
}
@media (min-width: 1200px) {
  .filter {
    margin-top: 30px;
  }
  .filter--sticky {
    position: -webkit-sticky;
    position: sticky;
    top: 100px;
    margin-bottom: 120px;
  }
}
/*==============================
  Home
==============================*/
.home {
  background: linear-gradient(90deg, #f8b80b, #f16604);
  display: flex;
  align-items: center;
  justify-content: center;
}

.home--hero {
  background: transparent;
  border-bottom: none;
}
.home_banner_con2 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.featured_nft {
  border-radius: 20px !important;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -khtml-border-radius: 20px;
  -ms-border-radius: 20px;
  overflow: hidden;
  padding: 0px;
  display: flex;
  flex-direction: column;
  margin: 0px auto;
  /* margin-top: 40px; */
  width: 310px;
}
.featured_img {
  height: 300px;
  min-height: 310px;
  background-color: rgba(0, 0, 0, 0.11) !important;
  overflow: hidden;
  -webkit-border-radius: 20px 20px 0px 0px;
  -moz-border-radius: 20px 20px 0px 0px;
  -ms-border-radius: 20px 20px 0px 0px;
  border-radius: 20px 20px 0px 0px;
  -khtml-border-radius: 20px 20px 0px 0px;
}

.featured_home_comp {
  min-height: 510px;
  overflow: hidden;
  width: 450px;
}

/* the slides */
.slick-slide {
  /* margin: 0 10px; */
  /* padding-right: 10px !important; */
}
/* the parent */
.slick-list {
  /* margin: 0 -10px; */
  margin-right: -10px !important;
}

.featured_tag_box {
  display: flex;
  align-items: center;
  height: 70px;
  background-color: #ffffff;
  padding: 10px 20px;
  margin-top: -8px;
}

.home__banner .slick-dots {
  bottom: 44px;
}

.featured_img img {
  /* border-radius: 20px 20px 0px 0px !important; */
  -webkit-border-radius: 20px 20px 0px 0px !important;
  -moz-border-radius: 20px 20px 0px 0px !important;
  -ms-border-radius: 20px 20px 0px 0px !important;
  border-radius: 20px 20px 0px 0px !important;
  -khtml-border-radius: 20px 20px 0px 0px !important;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.featured_img .slick-slider {
  -webkit-border-radius: 20px 20px 0px 0px !important;
  -moz-border-radius: 20px 20px 0px 0px !important;
  -ms-border-radius: 20px 20px 0px 0px !important;
  border-radius: 20px 20px 0px 0px !important;
  -khtml-border-radius: 20px 20px 0px 0px !important;
  overflow: hidden;
}

.slick-slider,
.slick-list,
.slick-track,
.slick-track div {
  height: 100%;
  width: 100%;
}

.slick-slider * {
  min-height: 0px;
  min-width: 0px;
}

.home_banner_con {
  display: flex;
}

.featured_nft_text {
  width: 100%;
}
.featured_nft_text h2 {
  color: var(--title-color);
  font-family: var(--head-font);
  font-weight: 600;
  font-size: 18px;
  transition: color 0.5s ease;
}
.home__content {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  min-height: 400px;
  width: 100%;
  border-radius: 17px;
  padding: 90px 30px 70px 30px;
}
.banner__image {
  max-width: 70%;
  padding-top: 95px;
  margin: auto;
}
.banner__bsv_logo {
  padding-left: 20px;
  width: 40%;
  height: 40%;
  min-width: 170px;
}
.banner__image1 {
  background: url('/img/banner1.png') no-repeat bottom center/cover;
}
.banner__image2 {
  background: url('/img/banner2.jpg') no-repeat bottom center/cover;
}
.home__banner {
  border-radius: 15px;
}
.home__content--center {
  align-items: center;
}
.home__content--center .home__title {
  text-align: center;
}
.home__content--center .home__text {
  text-align: center;
}
.home__content--center .home__btns {
  justify-content: center;
}
.home__title {
  position: relative;
  z-index: 2;
  color: var(--primary-bg-color);
  font-weight: 500;
  font-family: var(--head-font);
  margin-bottom: 0;
  line-height: 140%;
  width: 100%;
  text-align: center;
  letter-spacing: 0.1em;
}
.home__title b {
  font-weight: 800;
}
.home__title span {
  color: #ff9700;
}
.home__text {
  position: relative;
  z-index: 2;
  color: #ffffff;
  font-size: 26px;
  font-weight: 600;
  line-height: 28px;
  margin-bottom: 0;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
}
.home__text2 {
  position: relative;
  z-index: 2;
  color: #ffffff;
  font-size: 20px;
  font-family: var(--head-font);
  font-weight: 600;
  line-height: 1.5;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  /* text-transform: uppercase; */
  letter-spacing: 0.15em;
  text-align: center;
}
.home__btns {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
  margin-top: 10px;
  margin-left: auto;
  margin-right: auto;
  /* grid-gap: 18px; */
}
.home__btn {
  width: auto;
  min-width: 140px;
  height: 50px;
  background-color: transparent;
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  font-size: 16px;
  position: relative;
  z-index: 2;
  font-family: var(--head-font);
  font-weight: 600;
  padding: 0 20px;
  margin-top: 20px;
  /* margin-right: 20px; */
  border: white 1.5px solid;
}
.home_btn-bn {
  margin-right: 16px;
}
.terms__btn {
  width: auto;
  min-width: 140px;
  height: 50px;
  background-color: #ff9700;
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  font-size: 16px;
  position: relative;
  z-index: 2;
  font-family: var(--head-font);
  font-weight: 600;
  padding: 0 20px;
  margin-top: 20px;
  margin-right: 20px;
  border: white 1.5px solid;
}
.btn_term {
  margin-top: 10px;
}
.terms__btn:hover {
  background: #fff;
  color: #ff9700;
  border: #ff9700 1.5px solid;
}
.view__btn {
  width: auto;
  min-width: 140px;
  height: 50px;
  background-color: #f16604;
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  font-size: 16px;
  position: relative;
  z-index: 2;
  font-family: var(--head-font);
  font-weight: 600;
  padding: 0 20px;
  margin-top: 20px;
  margin-right: 20px;
  border: white 1.5px solid;
  border-radius: 5;
}
.view__btn:hover {
  background: #fff;
  color: #f16604;
  border: #f16604 1.5px solid;
}

.view_box_btn {
  color: #ff9700;
  font-size: 16px;
  font-family: var(--head-font);
  font-weight: 600;
  padding: 0 20px;
  margin-top: 20px;
  margin-right: 20px;
  cursor: pointer;
}

.view_box_btn:hover {
  color: #ff9700;
  text-decoration: underline;
}
.home__btn svg {
  fill: var(--title-color);
  width: 28px;
  height: auto;
  margin-right: 7px;
}

.home__btn:hover {
  color: #ff9700;
  background-color: white;
  border: white 1px solid;
}
.home__btn--clr {
  background-color: #ff9700;
  color: var(white);
}
.home__btn--clr:hover {
  background-color: white;
  color: #ff9700;
  border: white 1px solid;
}
@media (min-width: 768px) {
  .home__title {
    font-size: 36px;
    font-weight: 600;
  }
  .home__btn {
    margin-top: 30px;
    min-width: 160px;
    padding: 0 25px;
  }
  .home__btn:last-child {
    margin-right: 0;
  }
}

@media (min-width: 1024px) {
  .home__title {
    font-size: 32px;
    text-align: left;
  }
  .home__text {
    justify-content: left;
    margin-left: 0px;
    margin-right: 0px;
  }
  .home__btns {
    justify-content: center;
    margin-left: 0px;
    margin-right: 0px;
  }
}

.new-slider-feature {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 20px;
}
.inside-new {
  width: 310px;
  min-height: 310px;
}
.new-slider-feature .slick-arrow {
  display: none !important;
}
/* @media (max-width: 1200px){
    .inside-new{
      width: 100%;
    }
  } */
@media (max-width: 1023px) {
  /* .inside-new{
      width: 50%;
    } */
}
@media (min-width: 1024px) {
  .inside-new {
    width: 400px;
  }
}
@media (min-width: 1367px) {
  .inside-new {
    width: 450px;
  }
}
@media (min-width: 1200px) {
  .home__content {
    padding: 100px 30px 30px 30px;
  }
  .home__title {
    font-size: 42px;
    font-weight: 600;
  }
  .home__btn {
    margin-top: 0px;
  }
  .featured_nft {
    border-radius: 20px !important;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -khtml-border-radius: 20px;
    -ms-border-radius: 20px;
    overflow: hidden;
    padding: 0px;
    display: flex;
    flex-direction: column;
    margin: 0px auto;
    /* margin-top: 40px; */
    width: 450px;
  }
  .new-slider-feature {
    display: flex;
    justify-content: center;
  }
  /* .inside-new{
    width: 450px;
  } */
  .featured_img {
    height: 400px;
  }

  .featured_box {
    height: 450px;
  }
}
@media (min-width: 1400px) {
  .home__content {
    padding: 150px 30px 100px 30px;
  }
}
/*==============================
  Hero
==============================*/
.hero {
  display: block;
  width: 100%;
  margin-top: 30px;
  position: relative;
}
.hero .owl-dots {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 25px;
  position: relative;
}
.hero .owl-dot {
  margin-right: 10px;
}
.hero .owl-dot:last-child {
  margin-right: 0;
}
.hero .owl-dot span {
  display: block;
  height: 4px;
  width: 10px;
  border-radius: 2px;
  background-color: var(--primary-color);
  transition: 0.5s ease;
}
.hero .owl-dot.active span {
  width: 20px;
  background-color: #ff9700;
}
.hero .owl-stage-outer {
  border-radius: 16px;
}
.hero__slide {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  min-height: 360px;
  border-radius: 16px;
  overflow: hidden;
  padding: 40px 20px;
  position: relative;
}
.hero__slide:before {
  content: '';
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(
    30deg,
    rgba(0, 0, 0, 0.7) 0%,
    rgba(0, 0, 0, 0) 100%
  );
  z-index: 1;
}
.hero__title {
  position: relative;
  z-index: 2;
  color: var(--title-color);
  font-weight: 400;
  font-size: 30px;
  font-family: var(--head-font);
  margin-bottom: 0;
  line-height: 140%;
}
.hero__title b {
  font-weight: 500;
}
.hero__title span {
  color: #ff9700;
}
.hero__text {
  position: relative;
  z-index: 2;
  color: var(--primary-color);
  font-size: 16px;
  line-height: 28px;
  margin-bottom: 0;
  margin-top: 15px;
}
.hero__btns {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  width: 100%;
  margin-top: 10px;
}
.skeleton-moon {
  transition: all 0.2s linear;
  background: rgba(209, 215, 219, 1);
  /* animation: shimmer 1s linear infinite; */
  border-radius: 8px;
}

[style*='--aspect-ratio'] > :first-child {
  width: 100%;
}

[style*='--aspect-ratio'] > img {
  height: auto;
}

[style*='--aspect-ratio'] {
  position: relative;
}

[style*='--aspect-ratio'] > :first-child {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
}

[style*='--aspect-ratio']::before {
  content: '';
  display: block;
}

@supports not (aspect-ratio: 1/1) {
  [style*='--aspect-ratio']::before {
    height: 0;
    padding-bottom: calc(100% / (var(--aspect-ratio)));
  }
}
@supports (aspect-ratio: 1/1) {
  [style*='--aspect-ratio']::before {
    aspect-ratio: calc(var(--aspect-ratio));
  }
}
/* 
@keyframes shimmer {
  100% {
    background-position: -100% 0;
  }
} */
.hero__btn {
  width: auto;
  min-width: 140px;
  height: 50px;
  border-radius: 12px;
  background-color: var(--border-color);
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: var(--title-color);
  font-size: 14px;
  position: relative;
  z-index: 2;
  text-transform: uppercase;
  font-family: var(--head-font);
  font-weight: 500;
  padding: 0 20px;
  margin-top: 20px;
  margin-right: 20px;
}
.hero__btn svg {
  fill: var(--title-color);
  width: 28px;
  height: auto;
  margin-right: 7px;
}
.hero__btn:last-child {
  margin-right: 0;
}
.hero__btn:hover {
  color: #ff9700;
}
.hero__btn--clr {
  background-color: #ff9700;
  color: var(--title-color);
}
.hero__btn--clr:hover {
  background-color: var(--border-color);
  color: #ff9700;
}
@media (min-width: 576px) {
  .hero__slide {
    min-height: 400px;
  }
}
@media (min-width: 768px) {
  .hero .owl-dots {
    margin-top: 30px;
  }
  .hero__slide {
    padding: 40px;
    padding-right: 10%;
    min-height: 460px;
  }
  .hero__title {
    font-size: 36px;
  }
  .hero__btn {
    margin-top: 30px;
    margin-right: 30px;
    min-width: 160px;
    padding: 0 25px;
  }
  .hero__btn:last-child {
    margin-right: 0;
  }
}
@media (min-width: 992px) {
  .hero__slide {
    padding-right: 25%;
  }
}
@media (min-width: 1200px) {
  .hero .owl-dots {
    justify-content: flex-end;
    margin-top: -44px;
    margin-bottom: 0;
    padding-right: 124px;
    z-index: 2;
    position: absolute;
  }
  .hero .owl-dot span {
    background-color: rgba(255, 255, 255, 0.6);
  }
  .hero .owl-dot.active span {
    background-color: var(--title-color);
  }
  .hero__title {
    font-size: 42px;
  }
  .hero__btn {
    margin-top: 40px;
  }
  .hero__slide {
    padding: 60px;
    padding-right: 50%;
  }
}
/*==============================
  Сard
==============================*/
.card {
  border-radius: 16px;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 20px 16px 20px 16px;
  margin: 10px;
  border: 1px solid var(--border-color);
  background-color: var(--primary-bg-color);
  box-shadow: 0px 0px 15px 0px rgb(0 11 40 / 20%);
}
.card_padding {
  padding: 0px 15px 0px 15px;
}
.card__cover {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 8px;
  border-radius: 18px;
  cursor: pointer;
}
.imgcover_con {
  border-radius: 22px;
  width: 100%;
  /* height: 100%; */
  /* aspect-ratio: 1/1; */
  /* Using min-height, since aspect-ratio is not supported with certain devices(like: ipad mini and ipad 6) */
  min-height: 232px;
  display: flex;
  justify-content: center;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.11);
}
.card__image {
  /* object-fit: cover; */
  /* width: 100% !important;
  height: 100% !important; */
  /* position: absolute !important; */
}
.card__cover--carousel {
  display: block;
}
.card__cover--carousel img {
  border-radius: 16px;
}
.card__cover--carousel .owl-dots {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  left: 0;
  bottom: 20px;
  position: absolute;
  padding: 0 20px;
}
.card__cover--carousel .owl-dot {
  margin-right: 10px;
}
.card__cover--carousel .owl-dot:last-child {
  margin-right: 0;
}
.card__cover--carousel .owl-dot span {
  display: block;
  height: 4px;
  width: 10px;
  border-radius: 2px;
  background-color: var(--title-color);
  transition: background-color 0.5s ease;
}
.card__cover--carousel .owl-dot.active span {
  background-color: #ff9700;
}
.card__cover--video svg {
  position: absolute;
  top: 20px;
  right: 20px;
  pointer-events: none;
  fill: #bdbdbd;
  width: 20px;
  height: auto;
  pointer-events: none;
}
.card__title {
  display: block;
  width: 100%;
  color: var(--title-color);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  word-wrap: break-word;
  font-family: var(--head-font);
  font-weight: 500;
  font-size: 18px;
  margin-bottom: 15px;
  transition: color 0.5s ease;
}
.card__title a {
  color: var(--title-color);
}
.card__title a:hover {
  color: #ff9700;
}
.card__title:hover {
  color: #ff9700;
}
.card__author {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  position: relative;
}
.card__author--verified:after {
  content: '';
  position: absolute;
  display: block;
  width: 15px;
  height: 15px;
  border: 1px solid #ffffff;
  bottom: -2px;
  left: 24px;
  border-radius: 50%;
  background: url('/img/musicart-symbol.png') no-repeat center
    var(--orange-color);
  background-size: 14px auto;
  z-index: 1;
  pointer-events: none;
}
.card__author img {
  display: block;
  overflow: hidden;
  width: 40px !important;
  height: 40px;
  border-radius: 10px;
  margin-right: 10px;
}
.card__author a {
  font-family: var(--text-font);
  font-weight: 500;
  color: var(--primary-color);
  font-size: 16px;
}
.card__author a:hover {
  color: #ff9700;
}
.card__info a {
  width: 100%;
}
.card__info {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
  border-top: 1px solid var(--border-color);
  padding-top: 15px;
  margin-top: 15px;
}

.card__border_top {
  border-top: 1px solid var(--border-color);
}
.card__price {
  display: inline-flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
}
.card_price-wt {
  width: 100% !important;
}
.card_head1 {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.card_head1Text {
  font-size: 14px !important;
  line-height: 100% !important;
  color: var(--primary-color) !important;
}
.card__price span:first-child {
  font-size: 14px;
  line-height: 100%;
  color: var(--primary-color);
}
.card__price span:last-child {
  font-size: 16px;
  line-height: 100%;
  color: var(--title-color);
  margin-top: 10px;
  font-weight: 600;
}
.card__price .usd-price {
  font-size: 14px !important;
  line-height: 100%;
  color: var(--primary-color) !important;
  margin-left: 10px;
}
.card__likes {
  display: inline-flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.card__likes span {
  font-size: 13px;
  line-height: 18px;
  color: var(--primary-color);
  margin-left: 5px;
  transition: color 0.5s ease;
}
.card__likes i {
  font-size: 15px;
  color: var(--primary-color);
}

.card__likes .fas {
  color: #ff3060;
}

.card__likes span:empty {
  margin-left: 0;
}
.card__likes:hover svg {
  fill: #eb5757;
}
.card__likes--active svg {
  fill: #eb5757;
}
.card__likes--active span {
  color: var(--title-color);
}
.card__likes--active:hover svg {
  fill: #bdbdbd;
}
.card__likes--active:hover span {
  color: var(--primary-color);
}
.card__category {
  display: inline-flex;
  position: absolute;
  align-items: center;
  justify-content: center;
  height: 30px;
  min-width: 60px;
  border-radius: 16px;
  bottom: 10px;
  /* left: -webkit-calc(50% - 125px);
  left: -moz-calc(50% - 125px);
  left: calc(50% - 125px); */
  left: 6px;
  border: 2px solid #ff9700;
  color: var(--title-color);
  font-size: 14px;
  font-weight: 600;
  background-color: rgba(255, 255, 255, 0.6);
  padding: 0 12px;
}
.card__time {
  display: inline-flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  height: 32px;
  border-radius: 16px;
  position: absolute;
  bottom: 0;
  left: 0;
  border: 2px solid #ff9700;
  color: var(--title-color);
  font-size: 12px;
  background-color: var(--border-color);
  font-weight: 400;
  padding: 0 12px;
}
.card__time svg {
  position: relative;
  top: auto;
  left: auto;
  right: auto;
  bottom: auto;
  width: 16px;
  height: auto;
  margin-right: 6px;
  fill: #ff9700;
  margin-left: -3px;
}
.card__time--clock {
  border-color: #f7630c;
}
.card__time--clock svg {
  fill: #f7630c;
}

/*==============================
  Post
==============================*/
.post {
  position: relative;
  margin-top: 20px;
  border-radius: 16px;
  width: 100%;
  overflow: hidden;
  background-color: var(--border-color);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  height: calc(100% - 20px);
}
.post__img {
  display: block;
  width: 100%;
  position: relative;
  background-color: #000;
}
.post__img:before {
  content: '';
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(
    45deg,
    rgba(0, 0, 0, 0.8) 0%,
    rgba(0, 0, 0, 0) 100%
  );
  opacity: 0.1;
  transition: opacity 0.5s ease;
  z-index: 2;
}
.post__img img {
  width: 100%;
  position: relative;
  z-index: 1;
}
.post__img:hover:before {
  opacity: 0.3;
}
.post__content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  padding: 20px;
  background-color: var(--border-color);
  border-radius: 0 0 16px 16px;
  height: 100%;
  position: relative;
}
.post__category {
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 28px;
  min-width: 70px;
  width: auto;
  color: var(--title-color);
  font-size: 13px;
  background-color: #ff9700;
  border-radius: 10px;
  padding: 0 15px;
  margin-bottom: 20px;
  position: absolute;
  bottom: 100%;
  left: 20px;
  z-index: 2;
}
.post__category:hover {
  color: var(--title-color);
  background-color: var(--border-color);
}
.post__title {
  display: block;
  font-family: var(--head-font);
  font-weight: 400;
  font-size: 24px;
  line-height: 140%;
  color: var(--title-color);
  margin-bottom: 20px;
  overflow: hidden;
  width: 100%;
}
.post__title a {
  color: var(--title-color);
  display: block;
}
.post__title a:hover {
  color: #ff9700;
}
.post__meta {
  margin-top: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 20px;
}
.post__date {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  font-size: 13px;
  color: var(--primary-color);
}
.post__date svg {
  fill: var(--title-color);
  width: 16px;
  height: auto;
  margin-right: 5px;
}
.post__comments {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  font-size: 13px;
  color: var(--primary-color);
}
.post__comments svg {
  fill: var(--title-color);
  width: 16px;
  height: auto;
  margin-right: 5px;
}

.music_file_comp {
  cursor: pointer;
}
.music_file_comp:hover {
  background-color: #fff5ef;
}
.music_file_comp_active {
  background-color: #ffefe5 !important;
}
.music_file_comp_active_text {
  color: #ff9700;
}
@media (min-width: 768px) {
  .post {
    margin-top: 30px;
    height: calc(100% - 30px);
  }
}
/*==============================
  Asset
==============================*/

.asset__item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 20px 20px 70px 20px;
  width: 100%;
  border-radius: 16px;
  margin-top: 20px;
  background: var(white);
  border: 1px solid var(--border-color);
}
.asset__item .asset__img .image {
  object-fit: cover;
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 530px;
}
.asset__info {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  position: relative;
  width: 100%;
  margin-top: 20px;
}
.asset__desc {
  width: 100%;
}
.asset__desc h2 {
  font-family: var(--head-font);
  font-size: 18px;
  font-weight: 500;
  color: var(--title-color);
  margin-bottom: 15px;
}
.asset__desc p {
  font-family: var(--text-font);
  font-size: 16px;
  line-height: 26px;
  color: var(--primary-color);
  margin-bottom: 15px;
  display: block;
  width: 100%;
}
.asset__desc p:last-child {
  margin-bottom: 0;
}
.asset__authors {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px solid var(--border-color);
}
.asset__authors--tab {
  padding-top: 0;
  border-top: none;
}
.asset__authors li {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 15px;
}
.asset__authors li span {
  font-size: 13px;
  color: var(--primary-color);
  margin-bottom: 12px;
  line-height: 100%;
}
.asset__authors li p {
  font-size: 14px;
  line-height: 100%;
  color: var(--title-color);
  font-family: var(--text-font);
  font-weight: 400;
  margin-bottom: 0;
}
.asset__authors li:last-child {
  margin-bottom: 0;
}
.asset__author {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  position: relative;
}
.asset__author--verified:after {
  content: '';
  position: absolute;
  display: block;
  width: 15px;
  height: 15px;
  bottom: -2px;
  left: 28px;
  border-radius: 50%;
  border: 1px solid #ffffff;

  background: url('/img/musicart-symbol.png') no-repeat center
    var(--orange-color);
  background-size: 14px auto;
  z-index: 1;
  pointer-events: none;
}

@media only screen and (max-width: 375px) {
  .asset__author--verified:after {
    content: '';
    bottom: 16px;
  }
}
@media only screen and (max-width: 360px) {
  .asset__author--verified:after {
    content: '';
    bottom: 11px !important;
  }
}
@media only screen and (max-width: 414px) {
  .asset__author--verified:after {
    content: '';
    bottom: 16px;
  }
}
/* @media only screen and (max-width: 414px) {
  .asset__author--verified:after {
    content: '';
    bottom: 16px;
  }
} */
.nftbuyers {
  white-space: normal;
}
.nfticon {
  margin-right: 10px;
  margin-left: auto;
}
.asset__author img {
  display: block;
  overflow: hidden;
  width: 44px !important;
  height: 44px;
  border-radius: 10px;
  margin-right: 10px;
}
.asset__author {
  font-family: var(--head-font);
  font-weight: 500;
  color: var(--primary-color);
  font-size: 16px;
}
.asset__author a:hover {
  color: #ff9700;
}
.nft__tab__pane {
  height: 250px;
}
.nft__tab__content {
  padding: 0px 20px 0px 20px;
}
.nft__tab__pane--scroll {
  height: 270px;
  overflow-x: auto;
  overflow-y: auto;
  margin-top: 20px;
}
.nft__tab__pane--scroll::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}
.nft__tab__pane--scroll::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}
.nft__tab__pane--scroll .asset__action:first-child {
  margin-top: 0;
}
.asset__action {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  position: relative;
  margin-top: 20px;
}
.asset__action:last-child {
  margin-bottom: 3px;
}
.asset__action--verified:after {
  content: '';
  position: absolute;
  display: block;
  width: 15px;
  height: 15px;
  bottom: -2px;
  left: 28px;
  border-radius: 50%;
  border: 1px solid #ffffff;

  background: url('/img/musicart-symbol.png') no-repeat center
    var(--orange-color);
  background-size: 14px auto;
  z-index: 1;
  pointer-events: none;
}
.asset__action img {
  display: block;
  overflow: hidden;
  width: 44px !important;
  height: 44px;
  border-radius: 10px;
  margin-right: 10px;
}
.asset__action p {
  font-size: 14px;
  line-height: 22px;
  color: var(--primary-color);
  font-family: var(--text-font);
  font-weight: 400;
  margin-bottom: 0;
}
.asset__action p a {
  color: var(--title-color);
  font-weight: 500;
}
.asset__action p a:hover {
  color: #ff9700;
}
.asset__action p b {
  font-weight: 500;
  color: var(--title-color);
}
.asset__tabs {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  /* flex-wrap: wrap; */
  width: 100%;
  margin-top: 30px;
  border-bottom: 1px solid var(--border-color);
}
.asset__tabs li {
  margin-right: 30px;
}
.asset__tabs li:last-child {
  margin-right: 0;
}
.asset__tabs a {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  height: 36px;
  color: var(--primary-color);
  position: relative;
  font-size: 16px;
  font-family: var(--head-font);

  white-space: nowrap;
}
.asset__tabs a:before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 2px;
  display: block;
  background-color: #ff9700;
  transition: 0.5s ease;
  transform: translateY(2px);
  opacity: 0;
  border-radius: 2px 2px 0 0;
}
.asset__tabs a:hover {
  color: var(--title-color);
}
.asset__tabs a.active {
  color: var(--title-color);
  cursor: default;
}
.asset__tabs a.active:before {
  opacity: 1;
  transform: translateY(0);
}
.asset__btns {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
  width: 100%;
  margin-top: 20px;
  border-top: 1px solid var(--border-color);
}
.asset__btn {
  width: calc(100% - 10px);
  height: 50px;
  border-radius: 12px;
  background-color: var(--border-color);
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: var(--title-color);
  font-size: 14px;
  position: relative;
  z-index: 2;
  text-transform: uppercase;
  font-family: var(--head-font);
  font-weight: 500;
  padding: 0 20px;
  margin-top: 20px;
  margin-right: 20px;
}
.asset__btn svg {
  fill: var(--title-color);
  width: 28px;
  height: auto;
  margin-right: 7px;
}
.asset__btn:last-child {
  margin-right: 0;
}
.asset__btn:hover {
  color: var(--button-color-hover);
}
.asset__btn--clr {
  background-color: #ff9700;
  color: white;
}
.asset__btn--clr:hover {
  background-color: white;
  color: #ff9700;
  border: 1.5px solid #ff9700;
}
.owner_list_btn {
  color: white !important;
  width: 60px;
  height: auto;
  padding: 5px 10px;
  font-size: 14px !important;
  margin-top: 0px !important;
}

.owner_list_btn:hover {
  color: #ff9700 !important;
}
.asset__btn--full {
  width: 100%;
}
.asset__likes {
  display: inline-flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  /* position: absolute;
  bottom: 20px;
  right: 20px; */
  height: 28px;
  z-index: 1;
  margin-top: 5px;
}
.asset__likes svg {
  width: 18px;
  height: auto;
  transition: fill 0.5s ease;
  fill: #bdbdbd;
}
.asset__likes span {
  font-size: 13px;
  line-height: 18px;
  color: var(--primary-color);
  margin-left: 5px;
  transition: color 0.5s ease;
}
.asset__likes span:empty {
  margin-left: 0;
}
.asset__likes:hover svg {
  fill: #eb5757;
}
.asset__likes--active svg {
  fill: #eb5757;
}
.asset__likes--active span {
  color: var(--title-color);
}
.asset__likes--active:hover svg {
  fill: #bdbdbd;
}
.asset__likes--active:hover span {
  color: var(--primary-color);
}
/*  */
.asset_apple svg {
  width: 22px;
  height: auto;
  transition: fill 0.5s ease;
  fill: #bdbdbd;
}
.asset_apple:hover svg {
  fill: #eb5757;
}
.asset_spotify svg {
  width: 22px;
  height: auto;
  transition: fill 0.5s ease;
  fill: #bdbdbd;
}
.asset_spotify:hover svg {
  fill: #eb5757;
}
.accets_share svg {
  /* width: 18px; */
  height: auto;
  transition: fill 0.5s ease;
  fill: #bdbdbd;
}
.accets_share:hover svg {
  fill: #eb5757;
}

/*  */
.asset__wrap {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 20px;
  border-top: 1px solid var(--border-color);
  padding-top: 20px;
}
.asset__price {
  display: inline-flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: calc(50% - 10px);
  padding-left: 20px;
  width: auto;
}
.asset__price span:first-child {
  font-size: 14px;
  line-height: 100%;
  color: var(--title-color);
  font-weight: 500 !important;
}
.asset__price div:last-child {
  font-size: 14px;
  line-height: 100%;
  color: var(--primary-color);
  margin-top: 10px;
  white-space: nowrap;
  font-weight: 700;
}
.bsv-price-size {
  width: 100%;
  font-size: 16px;
  display: flex;
  flex-direction: row;
  /* justify-content: space-between; */
}
.bsv_size_size {
  font-size: 14px !important;
  font-weight: 500 !important;
}

.asset__timer {
  display: inline-flex;
  flex-direction: column;
  /* flex-wrap: wrap; */
  justify-content: flex-start;
  align-items: flex-start;
  width: 55%;
  position: relative;
  padding-right: 20px;
  border-right: 1px solid var(--border-color);
}
.asset__timer span {
  display: inline-flex;
  flex-direction: row;
  justify-content: flex-start;
  /* flex-wrap: wrap; */
  align-items: center;
  font-size: 14px;
  min-height: 20px;
  line-height: 120%;
  color: var(--primary-color);
}
.asset__timer span svg {
  width: 20px;
  height: auto;
  margin-right: 5px;
  fill: #ff9700;
}
.clock-svg svg {
  width: 20px;
  height: auto;
  margin-right: 5px;
  fill: #ff9700;
}
.asset__clock {
  font-size: 18px;
  line-height: 100%;
  color: var(--title-color);
  margin-top: 10px;
}
.nft__image--fullscreen {
  display: block;

  display: flex;
  justify-content: center;
  align-items: center;
}
.nft__image--fullscreen img {
  width: 100%;
  height: 800px;
  max-height: 100vh;
  padding: 20px;
  object-fit: contain;
}

@media (min-width: 576px) {
  .asset__authors {
    flex-direction: row;
  }
  .asset__authors li {
    margin-bottom: 0;
    margin-right: 60px;
  }
  .asset__authors li:last-child {
    margin-right: 0;
  }
  .asset__authors--tab {
    flex-direction: column;
  }
  .asset__authors--tab li {
    margin-right: 0;
    margin-bottom: 15px;
  }
  .asset__authors--tab li:last-child {
    margin-bottom: 0;
  }
}
@media (min-width: 768px) {
  .asset__item {
    margin-top: 30px;
    height: auto;
    min-height: calc(100% - 30px);
  }
  .asset__info {
    margin-top: 30px;
  }
}
/*==============================
  Sellers-list
==============================*/
.sellers-list {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
}
.sellers-list li {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  margin-top: 20px;
}
.sellers-list__number {
  display: inline-flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding-right: 15px;
  color: var(--primary-color);
  font-size: 14px;
}
.sellers-list__author {
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: calc(100% - 40px);
  position: relative;
  height: 60px;
  padding-left: 75px;
}
.sellers-list__author--verified:after {
  content: '';
  position: absolute;
  display: block;
  width: 15px;
  height: 15px;
  bottom: -2px;
  left: 42px;
  border-radius: 50%;
  border: 1px solid #ffffff;

  background: url('/img/musicart-symbol.png') no-repeat center
    var(--orange-color);
  background-size: 14px auto;
  z-index: 1;
  pointer-events: none;
}
.sellers-list__author img {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  overflow: hidden;
  width: 60px !important;
  height: 60px;
  border-radius: 16px;
}
.sellers-list__author a {
  font-family: var(--text-font);
  font-weight: 500;
  color: var(--title-color);
  font-size: 16px;
  margin-bottom: 5px;
}
.sellers-list__author a:hover {
  color: #ff9700;
}
.sellers-list__author span {
  color: var(--primary-color);
  font-size: 14px;
  font-weight: 400;
}
@media (min-width: 576px) {
  .sellers-list {
    height: 640px;
  }
  .sellers-list li {
    width: 50%;
  }
}
@media (min-width: 768px) {
  .sellers-list {
    height: 450px;
  }
  .sellers-list li {
    width: 33%;
    margin-top: 30px;
  }
}
@media (min-width: 1200px) {
  .sellers-list {
    height: 300px;
  }
  .sellers-list li {
    width: 20%;
    margin-top: 40px;
  }
}

/*==============================
  Partners
==============================*/
.partners {
  margin-top: 60px;
}
.partners__img img {
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
  opacity: 0.7;
  transition: 0.5s;
}
.partners__img:hover img {
  -webkit-filter: grayscale(0);
  filter: grayscale(0);
  opacity: 1;
}
@media (min-width: 768px) {
  .partners {
    margin-top: 70px;
  }
}
/*==============================
  Feature
==============================*/
.feature {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  position: relative;
  overflow: hidden;
  margin-top: 20px;
  padding-bottom: 20px;
  /* border-bottom: 1px solid var(--border-color); */
}
.feature__icon {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  border-radius: 12px;
  background-color: rgba(97, 100, 255, 0.15);
}
.feature__icon svg {
  fill: #ff9700;
  width: auto;
  height: 26px;
}
.feature__icon--purple {
  background-color: rgba(128, 81, 212, 0.15);
}
.feature__icon--purple svg {
  fill: #8051d4;
}
.feature__icon--red {
  background-color: rgba(235, 87, 87, 0.15);
}
.feature__icon--red svg {
  fill: #eb5757;
}
.feature__icon--blue {
  background-color: rgba(47, 128, 237, 0.15);
}
.feature__icon--blue svg {
  fill: #2f80ed;
}
.feature__icon--green {
  background-color: rgba(37, 165, 106, 0.15);
}
.feature__icon--green svg {
  fill: #25a56a;
}
.feature__title {
  display: flex;
  font-family: var(--head-font);
  font-size: 18px;
  line-height: 28px;
  font-weight: 600;
  color: var(--title-color);
  margin-top: 35px;
  text-align: center;
  width: calc(100%);
  min-height: 60px;
  justify-content: center;
}
.feature__text {
  display: block;
  width: 100%;
  font-family: var(--text-font);
  font-size: 16px;
  line-height: 26px;
  color: var(--primary-color);
  margin-bottom: 0;
  margin-top: 15px;
  padding: 0px 15px 0px 15px;
}
.feature__text a {
  color: #ff9700;
  text-decoration: underline;
}
.feature__text a:hover {
  color: #ff9700;
  text-decoration: none;
}
.feature--last {
  border-bottom: none;
  padding-bottom: 0;
}
@media (min-width: 768px) {
  .feature {
    margin-top: 30px;
    padding-bottom: 0;
    border-bottom: none;
    padding-right: 20px;
  }
}
@media (min-width: 1200px) {
  .feature {
    margin-top: 40px;
  }
}
/*==============================
  Step
==============================*/
.step {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  position: relative;
  overflow: hidden;
  width: 100%;
  margin-top: 20px;
  padding: 20px;
  border-radius: 16px;
  background-color: #16151a;
  border: 1px solid var(--border-color);
}
.step__number {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  width: 50px;
  height: 50px;
  font-family: 'Inter', sans-serif;
  line-height: 100%;
  font-weight: 500;
  font-size: 22px;
  color: #ff9700;
  background-color: rgba(97, 100, 255, 0.15);
}
.step__title {
  font-family: var(--head-font);
  font-size: 18px;
  font-weight: 400;
  color: var(--title-color);
  margin-bottom: 0;
  margin-left: 15px;
  width: calc(100% - 65px);
}
.step__text {
  display: block;
  width: 100%;
  font-size: 16px;
  line-height: 26px;
  color: var(--primary-color);
  margin-bottom: 0;
  margin-top: 15px;
}
.step__text a {
  color: #ff9700;
  text-decoration: underline;
}
.step__text a:hover {
  color: #ff9700;
  text-decoration: none;
}
@media (min-width: 768px) {
  .step {
    margin-top: 30px;
  }
}

/*==============================
  Author
==============================*/
.author {
  border-radius: 16px;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 20px;
  margin: 30px calc(var(--gutter) / 2) 10px calc(var(--gutter) / 2);
  border: 1px solid var(--secondary-bg-color);
  background-color: var(--primary-bg-color);
  box-shadow: 0px 0px 15px 0px rgb(0 11 40 / 20%);
}
.author--page {
  overflow: visible;
  margin-top: 0;
  z-index: 2;
}
.author--page .author__avatar {
  width: 70%;
  height: 70%;
  margin-left: auto;
  margin-right: auto;
  background-color: transparent !important;
}
.author--page .author__avatar img {
  /* width: 160px;
  max-width: 160px;
  height: 160px; */
  margin-top: -80px;
  margin-bottom: 15px;
}
.author--page .author__avatar--verified:after {
  width: 24px;
  height: 24px;
  background-size: 18px auto;
  bottom: 10px;
}
.author__cover {
  width: 100%;
  height: 120px;
  cursor: pointer;
  background-repeat: no-repeat;
  background-color: var(--primary-bg-color);
  border-radius: 16px 16px 0 0;
}
.author__cover--bg {
  border: none;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  background-color: rgba(0, 0, 0, 0.11) !important;
}

/* .author__meta a {
  margin-left: 20px;
} */
.author__meta {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  padding: 0 0px 0px;
  background-color: var(--primary-bg-color);
  border-radius: 0 0 16px 16px;

  height: auto;
}
.author__meta {
  font-family: var(--text-font);
}
.author__avatar {
  display: block;
  width: 60px;
  height: 60px;
  border-radius: 16px;
  margin-top: -30px;
  margin-bottom: 12px;
  position: relative;
  background: rgba(0, 0, 0, 0.11) !important;
}
.author__avatar1 {
  display: block;
  width: 40px !important;
  height: 40px !important;
  border-radius: 16px;
  position: relative;
  background: rgba(0, 0, 0, 0.11) !important;
}
.author__avatar img {
  width: 100%;
  border-radius: 16px;
}
.author__avatar--verified:after {
  content: '';
  position: absolute;
  display: block;
  width: 15px;
  height: 15px;
  bottom: -2px;
  right: 0;
  margin: 0 auto;
  border-radius: 50%;
  border: 1px solid #ffffff;

  background: url('/img/musicart-symbol.png') no-repeat center
    var(--orange-color);
  background-size: 14px auto;
  z-index: 1;
  pointer-events: none;
}
.turning-screens .author__avatar--verified:after {
  left: 50px !important;
}
.author__name h1 {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.author__name {
  display: block;
  width: 100%;
  font-size: 18px;
  color: var(--title-color);
  font-family: var(--head-font);
  font-weight: 500;
  margin-bottom: 6px;
}
.card__email {
  display: block;
  width: 100%;
  font-size: 16px;
  color: var(--title-color);
  font-family: var(--head-font);
  margin-bottom: 6px;
}
.card__collector {
  display: block;
  width: 100%;
  font-size: 16px;
  color: #404040;
  font-family: var(--head-font);
  margin-bottom: 10px;
  word-break: break-all;
  min-height: 30px;
}
.author__name a {
  color: var(--title-color);
}
.author__name a:hover {
  color: #ff9700;
}
.author__nickname div {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.author__nickname {
  display: block;
  width: 100%;
  font-size: 16px;
  color: #404040;
  font-family: var(--head-font);
  font-weight: 500;
  margin-bottom: 10px;
  word-break: break-all;
  min-height: 30px;
}
.artist_followers_tag {
  color: #bbb8b8;
  position: absolute;
  right: 0;
  margin-right: 20px;
  margin-top: 6px;
}
.artist_followers_reject {
  color: red;
  position: absolute;
  right: 0;
  margin-right: 20px;
  margin-top: 30px;
  border: 2px solid red;
  border-radius: 20px;
  padding: 2px 8px;
}
.author__nickname a {
  color: #d8d8d8;
}
.author__nickname a:hover {
  color: #ff9700;
}

.author__text {
  display: block;
  width: 100%;
  font-family: var(--text-font);
  font-size: 16px;
  line-height: 24px;
  color: var(--primary-color);
  font-weight: 400;
  margin-bottom: 0;
  min-height: 72px;
}
.author-bio {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.author-bio-text {
  -webkit-line-clamp: 1 !important;
  min-height: 100px;
  max-height: 100px;
}

.author__wrap {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border-top: 1px solid var(--border-color);
  padding-top: 5px;
  margin-top: 15px;
  flex-wrap: wrap;
  padding-left: 8px;
}

.author__followers_seller_page {
  display: flex;
  flex-direction: column;
}
.author__followers_seller_page span {
  font-size: 20px;
  line-height: 100%;
  font-family: var(--text-font);
  margin-right: 6px;
}
.author__followers_seller_page p {
  font-size: 14px;
  color: var(--primary-color);
  line-height: 100%;
  margin-top: 8px;
}
.author__followers {
  display: inline-flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  min-width: 45px;
}
.author__followers p {
  font-size: 20px;
  line-height: 100%;
  color: var(--title-color);
  margin-bottom: 0;
  font-family: var(--text-font);
}
.author__followers span {
  font-size: 14px;
  color: var(--primary-color);
  line-height: 100%;
  margin-top: 8px;
}
.author__follow {
  width: 100%;
  max-width: 110px;
  min-width: 100px;
  height: 42px;
  border-radius: 12px;
  background-color: #ff9700;
  color: white;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 13px;
  position: relative;
  z-index: 2;
  text-transform: uppercase;
  font-family: var(--head-font);
  font-weight: 500;
  /* padding: 15px; */
}
.disable_btn {
  cursor: default;
  opacity: 0.8;
}
.disable_btn:hover {
  background-color: #ff9700 !important;
  color: #ffffff !important;
  border: none;
}
.author__follow__disabled {
  cursor: default;
  opacity: 0.8;
}
.follow-btn-loader {
  color: white;
}
.author__follow:hover {
  background-color: white;
  color: #ff9700;
  border: #ff9700 1.5px solid;
}

.author__follow__disabled:hover {
  background-color: #ff9700;
  color: #ffffff;
  border: none;
}
.author__follow--true {
  background-color: #ff9700;
  color: var(--title-color);
}
.author__follow--true:hover {
  background-color: var(--border-color);
  color: #ff9700;
}
.author__social {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  /* justify-content: space-between; */
  /* align-items: center; */
  flex-wrap: wrap;
  width: 100%;
}
.author__social a {
  margin-top: 20px;
  margin-right: 18px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 25px;
  height: 20px;
  position: relative;
}
.author__social a svg {
  width: 20px;
  height: 20px;
  fill: var(--primary-color);
  transition: fill 0.5s ease;
}
.author__social a:last-child {
  margin-right: 0;
}
.author__social a:hover svg {
  fill: #ff9700;
}
.author__code P {
  white-space: nowrap;
}
.author__code {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  margin-top: 15px;
  height: 32px;
  background-color: var(--border-color);
  border-radius: 10px;
  position: relative;
}
.author__code input {
  width: 100%;
  padding: 0 40px 0 15px;
  height: 30px;
  border: none;
  background-color: transparent;
  color: var(--primary-color);
  font-size: 14px;
  font-family: var(--text-font);
  font-weight: 400;
  white-space: nowrap;
  text-overflow: ellipsis;
  word-wrap: break-word;
  cursor: default;
  position: relative;
  z-index: 1;
  transition: 0s;
}
.author__code ::-moz-selection {
  background: transparent;
  color: var(--primary-color);
}
.author__code ::selection {
  background: transparent;
  color: var(--primary-color);
}
.author__code button {
  position: absolute;
  z-index: 2;
  cursor: pointer;
  width: 40px;
  top: 0;
  right: 0;
  height: 32px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  transition: 0s;
}
.author__code button span {
  position: absolute;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: auto;
  background-color: var(--border-color);
  border-radius: 8px;
  font-size: 12px;
  line-height: 22px;
  padding: 0 8px;
  color: var(--primary-color);
  bottom: 100%;
  margin-bottom: 5px;
  transform: scale(0);
  pointer-events: none;
  opacity: 0;
  transition: 0.4s ease;
  transition-property: opacity, transform;
}

.author__code button svg {
  fill: #ff9700;
  width: 18px;
  height: auto;
  opacity: 0.75;
  transition: opacity 0.5s ease;
}
.author__code button.active span {
  transform: scale(1);
  opacity: 1;
}
.author__code button.active svg {
  opacity: 1;
}
.author__code button:hover svg {
  opacity: 1;
}
.author__code_truncate {
  width: 90%;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0px 10px;
  white-space: nowrap;
}
.author__link {
  display: inline-flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-top: 15px;
  font-size: 14px;
  line-height: 20px;
  color: var(--primary-color);
  font-family: var(--head-font);
  font-weight: 400;
}
.author__link svg {
  width: 20px;
  height: auto;
  fill: #ff9700;
  margin-right: 5px;
}
.author__link:hover {
  color: #ff9700;
}
@media (min-width: 576px) {
  .author__text {
    min-height: 96px;
  }
  .author--page .author__text {
    padding-right: 40%;
  }
}
@media (min-width: 768px) {
  .author--page {
    margin-top: 0;
  }
  .author--page .author__text {
    padding-right: 50%;
  }
  .author__text {
    min-height: 72px;
  }
}
@media (min-width: 1200px) {
  .author--page {
    padding-right: 20px;
  }
  .author--page .author__text {
    padding-right: 0;
  }
}
/*==============================
  Profile
==============================*/
.profile {
  display: flex;
  /* flex-direction: column; */
  /* justify-content: flex-start; */
  /* align-items: flex-start; */
  background-color: var(--primary-bg-color);
  padding: 0 20px;
  border-radius: 16px;
  position: relative;
  margin-top: 20px;
  border: 1px solid var(--secondary-bg-color);
  background-color: var(--primary-bg-color);
  box-shadow: 0px 0px 15px 0px rgb(0 11 40 / 20%);
}
.profile__banner {
  background: linear-gradient(
    90deg,
    rgba(252, 213, 10, 1) 0%,
    rgba(241, 102, 4, 1) 100%
  );
}
.profile__tabs {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  overflow: hidden;
  width: 100%;
}
.profile__tabs li {
  margin-right: 20px;
}
.profile__tabs li:last-child {
  margin-right: 0;
}
.profile__tabs a {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  height: 40px;
  color: var(--primary-color);
  position: relative;
  font-size: 14px;
  font-family: var(--head-font);
  white-space: nowrap;
}
.profile__tabs a:hover {
  color: #ff9700;
}
.profile__tabs a:before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 2px;
  display: block;
  background-color: #f16604;
  transition: 0.5s ease;
  transform: translateY(2px);
  opacity: 0;
  border-radius: 2px 2px 0 0;
}
.profile__tabs a.active {
  color: var(--title-color);
  cursor: default;
}
.profile__tabs a.active:before {
  opacity: 1;
  transform: translateY(0);
}
@media (min-width: 576px) {
  .profile__tabs li {
    margin-right: 25px;
  }
  .profile__tabs li:last-child {
    margin-right: 0;
  }
}
@media (min-width: 768px) {
  .profile__tabs {
    border-bottom: none;
    margin-bottom: 0;
    width: auto;
    margin-right: auto;
  }
  .profile__tabs li {
    margin-right: 30px;
  }
  .profile__tabs li:last-child {
    margin-right: 0;
  }
  .profile__tabs a {
    align-items: center;
    height: 70px;
  }
}
/*==============================
  Contacts
==============================*/
.contacts__list {
  margin-top: 20px;
}
.contacts__list li {
  margin-bottom: 15px;
}
.contacts__list li:last-child {
  margin-bottom: 0;
}
.contacts__list a {
  font-size: 16px;
  color: var(--title-color);
  font-family: var(--head-font);
  font-weight: 400;
}
.contacts__list a:hover {
  color: #ff9700;
}
.contacts__social {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  width: 100%;
}
.contacts__social a {
  margin-top: 20px;
  margin-right: 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 22px;
  height: 22px;
  position: relative;
}
.contacts__social a svg {
  width: 100%;
  height: auto;
  fill: #bdbdbd;
  transition: fill 0.5s ease;
}
.contacts__social a:last-child {
  margin-right: 0;
}
.contacts__social a:hover svg {
  fill: #ff9700;
}
@media (min-width: 768px) {
  .contacts__social {
    margin-top: 10px;
  }
}
/*==============================
  Article
==============================*/
.article {
  display: block;
  position: relative;
  width: 100%;
  margin-top: 25px;
}
.article__category {
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 28px;
  min-width: 70px;
  width: auto;
  color: var(--title-color);
  font-size: 13px;
  background-color: #ff9700;
  border-radius: 10px;
  padding: 0 15px;
}
.article__category:hover {
  color: var(--title-color);
  background-color: var(--border-color);
}
.article__date {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  font-size: 13px;
  color: var(--primary-color);
  height: 28px;
}
.article__date svg {
  fill: var(--title-color);
  width: 16px;
  height: auto;
  margin-right: 5px;
}
.article__content {
  position: relative;
}
.article__content img {
  display: block;
  width: 100%;
  height: auto;
  margin-bottom: 25px;
  border-radius: 16px;
}
.article__content iframe {
  display: block;
  width: 100%;
  height: 240px;
  margin-bottom: 20px;
  border-radius: 16px;
  border: none;
}
.article__content h1,
.article__content h2,
.article__content h3,
.article__content h4,
.article__content h5,
.article__content h6 {
  font-family: var(--text-font);
  font-weight: 400;
  color: var(--title-color);
  margin-bottom: 15px;
  line-height: 140%;
}
.article__content h1:last-child,
.article__content h2:last-child,
.article__content h3:last-child,
.article__content h4:last-child,
.article__content h5:last-child,
.article__content h6:last-child {
  margin-bottom: 0;
}
.article__content h1 {
  font-size: 30px;
}
.article__content h2 {
  font-size: 28px;
}
.article__content h3 {
  font-size: 24px;
}
.article__content h4 {
  font-size: 22px;
}
.article__content h5 {
  font-size: 18px;
}
.article__content h6 {
  font-size: 16px;
}
.article__content p {
  display: block;
  font-size: 16px;
  line-height: 26px;
  font-weight: 400;
  color: var(--primary-color);
  margin-bottom: 20px;
}
.article__content p b {
  font-weight: 600;
}
.article__content p a {
  color: #ff9700;
  text-decoration: underline;
}
.article__content p a:hover,
.article__content p a:focus {
  color: #ff9700;
  text-decoration: none;
}
.article__content p:last-child {
  margin-bottom: 0;
}
.article__content blockquote {
  display: block;
  position: relative;
  font-family: var(--head-font);
  padding: 0 0 0 20px;
  font-size: 16px;
  line-height: 28px;
  color: var(--title-color);
  font-weight: 400;
  margin-bottom: 20px;
}
.article__content blockquote:before {
  content: '';
  position: absolute;
  display: block;
  top: 2px;
  bottom: 2px;
  left: 0;
  width: 3px;
  background-color: #ff9700;
  border-radius: 3px;
}
.article__content blockquote:last-child {
  margin-bottom: 0;
}
.article__content ul {
  margin-bottom: 20px;
  display: block;
}
.article__content ul li {
  font-size: 16px;
  line-height: 28px;
  font-weight: 400;
  color: var(--primary-color);
  padding-left: 20px;
  position: relative;
}
.article__content ul li:before {
  content: '';
  position: absolute;
  display: block;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background-color: #ff9700;
  left: 0;
  top: 50%;
  margin-top: -2px;
}
.article__content ul:last-child {
  margin-bottom: 0;
}
.article__meta {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: 20px;
}
@media (min-width: 576px) {
  .article__content iframe {
    height: 280px;
  }
}
@media (min-width: 768px) {
  .article {
    margin-top: 25px;
  }
  .article__content h1 {
    font-size: 36px;
  }
  .article__content h2 {
    font-size: 32px;
  }
  .article__content h3 {
    font-size: 28px;
  }
  .article__content h4 {
    font-size: 24px;
  }
  .article__content h5 {
    font-size: 20px;
  }
  .article__content h6 {
    font-size: 18px;
  }
  .article__content iframe {
    height: 400px;
  }
  .article__content img {
    margin-bottom: 30px;
  }
  .article__meta {
    margin-bottom: 25px;
  }
}
@media (min-width: 1200px) {
  .article__content iframe {
    height: 460px;
  }
}
.actions {
  position: absolute;
  bottom: 20px;
  right: 20px;

  display: flex;
  align-items: center;
  justify-content: center;
  height: 45px;
}

/*==============================
  Share
==============================*/
.share_wrapper {
  padding: 16px;
}
.apper:hover {
  transition: all 0.2s ease 0s;
  box-shadow: rgb(4 17 29 / 25%) 0px 0px 4px 0px;
  background-color: rgb(251, 253, 255);
  opacity: 1;
}

.share {
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: nowrap;
  width: 100%;
}
.share--asset {
  /* position: absolute;
  bottom: 20px;
  left: 20px; */
  margin-top: 0;
  z-index: 1;
  width: auto;
  display: inline-flex;
}

.share--asset .share__link {
  /* min-width: 50px; */
  display: flex;
  justify-content: flex-start;
  margin-top: 0;
}
.share--asset .share__link svg {
  margin-right: 0;
}
.share--asset span {
  display: none;
}
.share__link {
  /* margin-top: 20px; */
  /* margin-right: 20px; */
  display: flex;
  /* flex-direction: row; */
  justify-content: center;
  align-items: center;
  /* padding: 0 15px; */
  /* height: 28px; */
  /* border-radius: 10px; */
  border-radius: 50%;
  height: 28px;
  width: 28px;
  text-align: center;
  padding: 5px;
  color: #fff;
  font-size: 14px;
  background-color: #ff9700;
  font-family: var(--head-font);
}
.share__link svg {
  fill: var(--primary-bg-color);
  /* margin-right: 10px; */
  /* margin-top: 1px; */
  transition: fill 0.5s ease;
}
.share__link:last-child {
  margin-right: 0;
}
.share__link--fb {
  background-color: #509cce;
}
.share__link--tw {
  background-color: #55acee;
}
.share__link--vk {
  background-color: #4c6c91;
}
.share__link--link {
  background-color: #4c6c91;
}
.share__link--embed {
  background-color: gray;
}

.share_wrapper:hover .share__link {
  background-color: #ff9700;
  color: var(--primary-bg-color);
}
.share__code__wrapper {
  font-size: 1em;
  overflow: auto;
  margin: 16px 0;
  padding-left: 1.25rem;
  border-left: 4px solid #3291e9;
}
.share__code__wrapper::-webkit-scrollbar {
  width: 4px;
  height: 10px;
}

.share__code__wrapper::-webkit-scrollbar-track {
  width: 4px;
  box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}

.share__code__wrapper::-webkit-scrollbar-thumb {
  width: 4px;
  background-color: rgb(209, 209, 209);
  border-radius: 10px;
}

@media (min-width: 576px) {
  .share--asset span {
    display: block;
  }
  .share--asset .share__link {
    justify-content: flex-start;
  }
  .share--asset .share__link svg {
    margin-right: 10px;
  }
}
/*==============================
  Comments
==============================*/
.comments {
  margin-top: 50px;
  padding-top: 50px;
  border-top: 1px solid var(--border-color);
}
.comments__title {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 30px;
}
.comments__title h4 {
  color: var(--title-color);
  font-weight: 400;
  font-size: 24px;
  line-height: 100%;
  margin-bottom: 0;
  position: relative;
  font-family: var(--head-font);
}
.comments__title span {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: auto;
  min-width: 20px;
  height: 20px;
  padding: 0 5px;
  border-radius: 6px;
  background-color: #ff9700;
  font-size: 12px;
  color: var(--title-color);
  font-weight: 400;
  margin-left: 10px;
}
.comments__autor {
  display: block;
  position: relative;
  padding-left: 55px;
  margin-bottom: 20px;
}
.comments__avatar {
  position: absolute;
  top: 0;
  left: 0;
  width: 40px;
  border-radius: 10px;
}
.comments__name {
  display: block;
  font-size: 16px;
  color: var(--title-color);
  line-height: 20px;
  font-weight: 400;
  font-family: var(--head-font);
}
.comments__time {
  display: block;
  font-size: 12px;
  color: var(--primary-color);
  line-height: 20px;
  font-weight: 400;
}
.comments__text {
  display: block;
  margin-bottom: 0;
  color: var(--primary-color);
  font-size: 16px;
  line-height: 26px;
  padding: 20px 0;
  position: relative;
  border-top: 1px solid var(--border-color);
  border-bottom: 1px solid var(--border-color);
}
.comments__text b {
  font-weight: 600;
}
.comments__text a {
  color: #ff9700;
}
.comments__text a:hover {
  color: #ff9700;
  text-decoration: underline;
}
.comments__text span {
  display: block;
  margin-bottom: 20px;
  padding: 20px;
  position: relative;
  min-height: 80px;
  border-radius: 12px;
  color: var(--primary-color);
  background-color: var(--border-color);
}
.comments__item {
  margin-bottom: 20px;
  display: block;
  background-color: #16151a;
  border: 1px solid var(--border-color);
  padding: 20px;
  border-radius: 16px;
}
.comments__item--answer,
.comments__item--quote {
  margin-left: 20px;
}
.comments__actions {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: 20px 0 0;
  position: relative;
}
.comments__actions button {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-right: 20px;
  height: 20px;
}
.comments__actions button span {
  font-size: 12px;
  color: var(--primary-color);
  text-transform: uppercase;
  transition: color 0.5s ease;
  display: none;
}
.comments__actions button svg {
  width: 16px;
  height: auto;
  fill: #bdbdbd;
  transition: fill 0.5s ease;
}
.comments__actions button:hover span {
  color: var(--title-color);
}
.comments__actions button:hover svg {
  fill: #ff9700;
}
.comments__actions button:last-child {
  margin-right: 0;
}
.comments__actions button:last-child svg {
  width: 18px;
}
.comments__rate {
  display: inline-flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  position: absolute;
  left: 0;
  top: 20px;
}
.comments__rate button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: var(--primary-color);
  font-size: 14px;
  margin-right: 24px;
  position: relative;
  height: 20px;
}
.comments__rate button svg {
  width: 16px;
  height: auto;
  opacity: 0.6;
  transition: 0.5s ease;
  margin-top: 1px;
}
.comments__rate button:last-child {
  margin-right: 0;
}
.comments__rate button:last-child svg {
  margin-left: 8px;
  fill: #eb5757;
  width: 16px;
}
.comments__rate button:first-child svg {
  margin-right: 8px;
  fill: #25a56a;
  width: 16px;
}
.comments__rate button:first-child:before {
  content: '';
  position: absolute;
  display: block;
  left: 100%;
  margin-left: 12px;
  width: 1px;
  height: 16px;
  background-color: var(--border-color);
  top: 50%;
  transform: translateY(-50%);
}
.comments__rate button:hover {
  color: var(--title-color);
}
.comments__rate button:hover svg {
  opacity: 1;
}
.comments__form {
  border-radius: 16px;
  padding: 20px;
  border: 1px solid var(--border-color);
  background-color: #16151a;
  margin-top: 30px;
}
.comments__form .row {
  margin-left: -10px;
  margin-right: -10px;
}
.comments__form .col-12 {
  padding-left: 10px;
  padding-right: 10px;
}
.comments__form .sign__btn {
  margin: 0;
}
@media (min-width: 576px) {
  .comments__actions button span {
    display: block;
    margin-left: 6px;
  }
  .comments__form .sign__btn {
    width: 180px;
  }
}
@media (min-width: 768px) {
  .comments {
    margin-top: 60px;
    padding-top: 60px;
  }
  .comments__item--answer,
  .comments__item--quote {
    margin-left: 30px;
  }
}
/*==============================
  Footer
==============================*/
.footer {
  background-color: var(--border-color);
  padding: 30px 0 20px;
  position: relative;
  overflow: hidden;
}
.footer__bg {
  background: linear-gradient(90deg, #f8b80b, #f16604);
}
.footer_mark {
  margin: auto;
}
.about_links a {
  color: rgb(255, 255, 255);
  font-size: 30px;
  /* margin: 35px 50px 30px 0px; */
}
.footer__email {
  position: relative;
  width: 100%;
  background-color: transparent;
  height: 50px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  z-index: 1;
  padding: 0;
  border: none;
  transition: top 0.5s ease;
}
.footer__email input {
  width: 100%;
  height: 50px;
  background-color: transparent;
  color: white;
  font-size: 14px;
  border-radius: 999px;
  border: 2px solid white;
  padding: 0 60px 0 20px;
  font-family: var(--text-font);
  font-weight: 400;
}
.footer__email input::placeholder {
  color: white;
  font-family: 'Roboto';
}
.footer__email input:focus {
  border: 2px solid white;
  /* border-color: var(--black-color); */
}
.footer__email button {
  position: absolute;
  right: 10px;
  height: 40px;
  width: 40px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  border-radius: 20px;
  background-color: #f16604;
  align-items: center;
}
.footer__email button img {
  width: 20px;
  height: 20px;
  height: auto;
  color: #fff;
  transition: 0.5s ease;
}
.footer__email button:hover svg {
  color: var(--black-color);
}
.footer__links:first-child {
  margin-top: 0px;
}
.footer__links {
  flex-direction: column;
  width: 100%;
  margin-top: 5px;
}

.footer__links a {
  display: inline-flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  color: var(--primary-color);
  font-family: var(--head-font);
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 1.25rem;
  padding: 1rem 0 0.5rem 0;
}
.footer__links a:hover {
  color: white;
}
.footer__title {
  display: block;
  color: white;
  font-family: var(--head-font);
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 1.25rem;
  margin-top: 1rem;
  padding: 0rem 0 0.5rem 0;
}

.footer_sm_img_wrapper {
  text-align: center;
  display: flex;
  justify-content: center;
  grid-gap: 30px;
  align-items: center;
}
.footer_sm_img_wrapper img {
  width: 100%;
  max-width: 100%;
  display: block;
  height: auto;
}

@media (min-width: 576px) {
  .footer {
    padding: 30px 0 20px;
  }
}

@media (min-width: 320px) and (max-width: 920px) {
  .footer__bg {
    display: flex;
    justify-content: center;
  }
}

@media (min-width: 768px) {
  .footer {
    padding: 20px 0 25px;
  }

  .footer__content {
    margin-top: 70px;
    padding-top: 25px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  .footer_sm_img_wrapper img {
    max-width: 100px;
    display: block;
    height: auto;
  }
}
@media (min-width: 1024) {
  .footer_mark {
    margin: 0;
  }
}
/*==============================
  Sign
==============================*/
.sign {
  display: block;
  position: relative;
}
.sign__content {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: auto;
  padding: 25px 0 0;
}
.sign__form {
  background-color: var(--primary-bg-color);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
  padding: 30px 20px;
  position: relative;
  width: 100%;
  max-width: 420px;
  border: 1px solid var(--secondary-bg-color);
  background-color: var(--secondary-bg-color);
  box-shadow: 0px 0px 15px 0px rgb(0 11 40 / 20%);
}
.sign__form--contacts,
.sign__form--profile,
.sign__form--mint {
  margin-top: 20px;
  max-width: 100%;
  padding: 20px 20px 5px;
  align-items: flex-start;
}
.sign__form--contacts .sign__btn,
.sign__form--profile .sign__btn,
.sign__form--mint .sign__btn {
  margin: 0 0 15px;
}
/* .sign__form--contacts .row,
.sign__form--profile .row,
.sign__form--mint .row {
  margin-left: -10px;
  margin-right: -10px;
} */
.sign__form--contacts .col-12,
.sign__form--profile .col-12,
.sign__form--mint .col-12 {
  padding-left: 10px;
  padding-right: 10px;
}
.sign__form--profile .sign__btn {
  margin: 5px 0 15px;
}
.sign__logo {
  display: block;
  margin-bottom: 30px;
  font-family: var(--head-font);
  font-size: 20px;
}
.sign__logo a {
  max-width: 100%;
  width: auto;
}
.sign__logo img {
  width: auto;
  height: 50px;
}
.sign__title {
  font-size: 16px;
  font-weight: 500;
  font-family: var(--head-font);
  color: var(--title-color);
  margin-bottom: 20px;
}
.sign_label_label {
  padding-left: 10px !important;
}
.sign__label {
  font-weight: 400;
  font-size: 14px;
  color: var(--primary-color);
  margin-bottom: 5px;
  padding-left: 20px;
}
.upld__label {
  font-weight: 400;
  font-size: 14px;
  color: var(--primary-color);
  margin-bottom: 5px;
  padding: 0px 10px;
}
.sign__label b {
  font-weight: 600;
  color: var(--title-color);
}
.sign__label-bold {
  font-weight: bold;
}
.multi_select_con {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.multi_select .ant-select-selector {
  min-height: 46px;
  background-color: var(--border-color) !important;
  border: 1px solid transparent !important;
  border-radius: 12px !important;
  position: relative !important;
  color: var(--title-color) !important;
  font-size: 16px !important;
  padding: 5px 10px;
}

.antd_date_picker input::placeholder,
.multi_select .ant-select-selection-placeholder {
  color: var(--primary-color) !important;
  opacity: 1 !important;
}

.antd_date_picker {
  background-color: transparent !important;
  border: 1px solid transparent !important;
  position: relative !important;
  color: var(--title-color) !important;
  font-size: 16px !important;
  padding: unset;
}

.ant-picker-range.antd_date_picker > .ant-picker-active-bar {
  display: none;
}

.ant-picker-range.antd_date_picker.ant-picker-focused {
  box-shadow: none;
}

.ant-picker-range.antd_date_picker > .ant-picker-input {
  min-height: 46px;
  border-radius: 12px;
  padding: 5px 10px;
  margin-right: 5px;
  background-color: var(--border-color);
}

.ant-picker-range.antd_date_picker > .ant-picker-input:focus-within {
  border: 1px solid #ff9700;
}

.ant-picker-range.antd_date_picker > .ant-picker-range-separator,
.ant-picker-suffix {
  display: none;
}

.sign__input {
  background-color: var(--border-color);
  border: 1px solid transparent;
  border-radius: 12px;
  height: 46px;
  position: relative;
  color: var(--title-color);
  font-size: 16px;
  width: 100%;
  padding: 0 20px;
  font-family: var(--text-font);
}

.sign__input.max-nft-size-input {
  width: 400px;
}

.sign__btn.max-nft-size-btn {
  width: 250px;
  margin: unset;
  height: unset;
  padding: 10px;
  border: 1.5px solid transparent;
  margin: 10px 0px 0px 0px;
}

.sign__input:focus {
  border-color: #ff9700 !important;
  --tw-ring-color: #ff9700 !important;
}

.sign__input.contact-number > input {
  background: transparent;
}

.sign__input.contact-number:focus-within {
  border-color: #ff9700;
}

.sign__input-select-contary {
  background-color: var(--border-color);
  border: 1px solid transparent;
  border-radius: 12px;
  height: 46px;
  position: relative;
  color: var(--title-color);
  font-size: 16px;
  width: 100%;
  padding: 0 20px;
  font-family: var(--text-font);
}

.contact_input {
  border-color: transparent;
}
.contact_input:focus {
  border-color: #ff9700 !important;
  --tw-ring-color: #ff9700 !important;
}
.contact_btn {
  background-color: #ff9700;
  border: 1px solid var(--border-color);
}
.contact_btn:hover {
  border-color: #ff9700 !important;
  background-color: transparent;
  color: #ff9700;
}

.sign__select {
  background: url('/img/arrow2.svg') no-repeat center right 20px
    var(--border-color);
  background-size: 18px auto;
  border: 1px solid transparent;
  border-radius: 12px;
  height: 46px;
  position: relative;
  color: var(--title-color);
  font-size: 16px;
  width: 100%;
  padding: 0 20px;
  cursor: pointer;
  font-family: var(--text-font);
}
.sign__select:focus {
  border-color: #ff9700 !important;
  --tw-ring-color: #ff9700 !important;
}
.promo_input_box {
  border-color: var(--border-color);
}
.promo_input_box:focus {
  border-color: #ff9700 !important;
  --tw-ring-color: #ff9700 !important;
}

.sign__textarea {
  background-color: var(--border-color);
  border: 1px solid transparent;
  border-radius: 12px;
  height: 144px;
  position: relative;
  color: var(--title-color);
  font-size: 16px;
  width: 100%;
  padding: 15px 20px;
  resize: none;
  font-family: var(--text-font);
  padding-right: 33px;
}
.sign__textarea:focus {
  border-color: #ff9700 !important;
  --tw-ring-color: #ff9700 !important;
}
.terms_textarea {
  height: 320px;
}
.sign__group-select-country {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  margin-bottom: 20px;
  width: 100%;
}
.sign__group {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-end;
  flex-wrap: wrap;
  position: relative;
  margin-bottom: 20px;
  width: 100%;
}
.sign__group--row .sign__label {
  padding-left: 0;
}
.sign__group--checkbox {
  width: 100%;
  text-align: left;
}
.sign__group--checkbox input:not(:checked),
.sign__group--checkbox input:checked {
  position: absolute;
  left: -9999px;
}
.sign__group--checkbox input:not(:checked) + label,
.sign__group--checkbox input:checked + label {
  font-size: 14px;
  color: var(--primary-color);
  font-weight: normal;
  position: relative;
  cursor: pointer;
  padding-left: 35px;
  line-height: 22px;
  margin: 0;
}
/*  */
.artist_profile_details {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: wrap;
  word-break: break-word;
  position: relative;
  margin-bottom: 20px;
  width: 100%;
}

/*  */
.sign_up_label_wrapper label {
  font-size: 14px;
  color: var(--primary-color);
  font-weight: normal;
  position: relative;
  cursor: pointer;
  padding-left: 0px;
  line-height: 22px;
  margin: 0;
}
/*  */
.ant-picker-input > input::placeholder {
  text-transform: capitalize;
}
.ant-picker-clear {
  background: none !important;
}
/*  */
.sign_up_label_wrapper label a {
  color: #ff9700;
}
.sign_up_label_wrapper label a:hover {
  color: #ff9700;
  text-decoration: underline;
}

.nft_cards_title_box {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/*  */
.sign__group--checkbox input:not(:checked) + label a,
.sign__group--checkbox input:checked + label a {
  color: #ff9700;
}
.sign__group--checkbox input:not(:checked) + label a:hover,
.sign__group--checkbox input:checked + label a:hover {
  color: #ff9700;
  text-decoration: underline;
}
.sign__group--checkbox input:not(:checked) + label:before,
.sign__group--checkbox input:checked + label:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 22px;
  height: 22px;
  background-color: var(--border-color);
  border: 1px solid transparent;
  border-radius: 6px;
}
.sign__group--checkbox input:not(:checked) + label:after,
.sign__group--checkbox input:checked + label:after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 22px;
  height: 22px;
  text-align: center;
  transition: 0.5s ease;
  background: url('/img/checkmark.svg') no-repeat center/20px auto;
  border-radius: 6px;
}
.sign__group--checkbox input:not(:checked) + label:after {
  opacity: 0;
  transform: scale(0);
}
.sign__group--checkbox input:checked + label:after {
  opacity: 1;
  transform: scale(1);
}
.sign__group--checkbox label::-moz-selection {
  background: transparent;
  color: var(--primary-color);
}
.sign__group--checkbox label::selection {
  background: transparent;
  color: var(--primary-color);
}
.submit-loader {
  color: var(--button-bg-color);
}
.btn-color {
  color: var(--button-bg-color) !important;
}
.sign__btn {
  width: 100%;
  height: 50px;
  border-radius: 12px;
  background-color: #ff9700;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: WHITE;
  font-size: 14px;
  position: relative;
  z-index: 2;
  text-transform: uppercase;
  font-family: var(--head-font);
  font-weight: 500;
  margin: 15px auto;
}
.sign__btn:hover {
  color: #ff9700;
  background-color: white;
  border: #ff9700 1.5px solid;
}
.auction__button {
  width: 100%;
  height: 50px;
  border-radius: 12px;
  background-color: #ff9700;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 14px;
  position: relative;
  z-index: 2;
  font-family: var(--head-font);
  font-weight: 500;
  margin: 15px auto;
}
.auction__button:hover {
  color: #ff9700;
  background-color: white;
  border: #ff9700 1.5px solid;
}
/*  */
.royalty_box_wrapper {
  display: flex !important;
  flex-direction: column !important;
  align-items: flex-start !important;
}
.royalty_btn_box {
  display: flex;
  justify-content: space-around;
  width: 320px;
}
.royalty__btn {
  width: 100px;
  height: auto;
  border-radius: 12px;
  background-color: #ff9700;
  display: flex;
  flex-direction: row;
  justify-content: center;
  color: WHITE;
  font-size: 14px;
}
.royalty__btn:hover {
  color: #ff9700;
  background-color: white;
  border: #ff9700 1.5px solid;
}
.royalty__btn:focus {
  color: #ff9700;
  background-color: white;
  border: #ff9700 1.5px solid;
}
.royalty__btn__selected {
  color: #ff9700;
  background-color: white;
  border: #ff9700 1.5px solid;
}
.type_btn_box {
  display: flex;
  justify-content: space-around;
  /* width: 320px; */
}
.type__btn {
  width: auto;
  height: auto;
  border-radius: 12px;
  background-color: #ff9700;
  display: flex;
  flex-direction: row;
  justify-content: center;
  color: WHITE;
  font-size: 14px;
  margin: 0px 3px;
}
.type__btn:hover {
  color: #ff9700;
  background-color: white;
  border: #ff9700 1.5px solid;
}
.type__btn:focus {
  color: #ff9700;
  background-color: white;
  border: #ff9700 1.5px solid;
}
.type__btn__selected {
  color: #ff9700;
  background-color: white;
  border: #ff9700 1.5px solid;
}
.type__btn__selected {
  color: #ff9700;
  background-color: white;
  border: #ff9700 1.5px solid;
}
/*  */
.sign__text {
  margin-top: 15px;
  font-size: 14px;
  line-height: 24px;
  color: var(--title-color);
  width: 100%;
  text-align: center;
}
.sign__text:first-child {
  margin-top: 0;
}
.sign__text a {
  position: relative;
  color: #ff9700;
}
.sign__text a:hover {
  color: #ff9700;
  text-decoration: underline;
}
.link {
  color: #ff9700;
}
.link:hover {
  color: var(--orange-color);
  text-decoration: underline;
  cursor: pointer;
}
.link_text {
  color: #ff9700;
  cursor: pointer;
}
.sign__text--small {
  font-size: 13px;
  line-height: 21px;
  text-align: left;
  color: var(--primary-color);
}
.sign__delimiter {
  font-size: 14px;
  color: var(--primary-color);
  line-height: 100%;
  width: 100%;
  display: block;
  text-align: center;
}
.sign__social {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: 15px;
  margin-top: 15px;
}
.sign__social a {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 46px;
  width: calc(33% - 10px);
  border-radius: 12px;
  color: var(--primary-bg-color);
}
.sign__social a svg {
  height: auto;
  fill: var(--primary-bg-color);
  transition: 0.5s ease;
}
.sign__social a.fb {
  background-color: #3b5999;
}
.sign__social a.fb svg {
  width: 10px;
}
.sign__social a.gl {
  background-color: #df4a32;
}
.sign__social a.gl svg {
  width: 18px;
}
.sign__social a.tw {
  background-color: #1da1f2;
  margin-right: 10px;
}
.sign__social a.tw svg {
  width: 20px;
}
.sign__social a:hover {
  background-color: var(--border-color);
}
.sign__social a:hover.fb svg {
  fill: #3b5999;
}
.sign__social a:hover.gl svg {
  fill: #df4a32;
}
.sign__social a:hover.tw svg {
  fill: #1da1f2;
}
.sign__value {
  font-size: 24px;
  color: var(--title-color);
  display: block;
  font-weight: 500;
  margin-left: 10px;
  font-family: var(--head-font);
}
.sign__radio {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  margin-top: 5px;
}
.sign__radio--single {
  padding-top: 20px;
  margin-top: 0;
  border-top: 1px solid var(--border-color);
}
.sign__radio li {
  position: relative;
  overflow: hidden;
  margin-bottom: 10px;
}
.sign__radio li:last-child {
  margin-bottom: 0;
}
.sign__radio input:not(:checked),
.sign__radio input:checked {
  position: absolute;
  left: -9999px;
}
.sign__radio label {
  display: block;
  margin: 0;
  position: relative;
  font-weight: 400;
  cursor: pointer;
  font-size: 16px;
  color: var(--primary-color);
  line-height: 24px;
  padding-left: 32px;
  transition: 0.5s ease;
}
.sign__radio label:before {
  content: '';
  display: block;
  position: absolute;
  width: 20px;
  height: 20px;
  border: 6px solid var(--border-color);
  background-color: transparent;
  border-radius: 50%;
  left: 0;
  top: 2px;
  transition: 0.5s ease;
}
.sign__radio label:hover {
  color: var(--title-color);
}
.sign__radio input:checked + label {
  color: var(--title-color);
}
.sign__radio input:checked + label:before {
  border-color: #ff9700;
}
.sign__file {
  position: relative;
  width: 100%;
  height: 46px;
  /* margin-bottom: 20px; */
  overflow: hidden;
  border-radius: 12px;
  display: block;
}
.sign__file input {
  position: absolute;
  opacity: 0;
  z-index: 1 !important;
}
.sign__file label {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  z-index: 2 !important;
  height: 46px;
  color: var(--primary-color);
  padding: 0 60px 0 20px;
  background-color: var(--border-color);
  margin: 0;
  width: 100%;
  font-size: 16px;
  cursor: pointer;
  transition: 0.5s ease;
  font-family: var(--head-font);
  font-weight: 400;
  position: absolute;
}
.sign__file label:hover {
  color: var(--title-color);
}
@media (min-width: 576px) {
  .sign__form {
    padding: 40px;
  }
  .sign__form--contacts,
  .sign__form--profile,
  .sign__form--mint {
    padding: 20px 20px 5px;
  }
  .sign__logo {
    margin-bottom: 40px;
  }
}
@media (min-width: 768px) {
  .sign__form--contacts,
  .sign__form--profile,
  .sign__form--mint {
    margin-top: 30px;
  }
  .sign__form--contacts .sign__btn,
  .sign__form--profile .sign__btn,
  .sign__form--mint .sign__btn {
    margin: 10px 0 15px;
  }
  .filter__keywords input {
    height: 100px;

    margin: 20px 20px 20px 20px;
    padding: 30px 60px 0 20px;
  }
  .filter__keywords {
    position: relative;
    width: 100%;

    background-color: transparent;
    height: auto;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    z-index: 1;
    padding: 0;
    border: none;
    transition: top 0.5s ease;
  }
  .discover_bar {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 80px;
    width: 100%;
  }
  .discover_sortby {
    width: auto;
  }
}
@media (min-width: 992px) {
  .sign__form--profile .sign__btn {
    width: 160px;
  }
}
/*==============================
  Page 404
==============================*/
.page-404 {
  display: block;
  position: relative;
}
.page-404__wrap {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: auto;
  padding: 25px 0 0;
}
.page-404__content {
  background-color: #16151a;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  padding: 20px;
  position: relative;
  width: 100%;
  max-width: 420px;
  border: 1px solid var(--border-color);
}
.page-404__title {
  position: relative;
  color: var(--title-color);
  line-height: 100%;
  font-size: 100px;
  margin-bottom: 10px;
  font-weight: 500;
  font-family: var(--head-font);
}
.page-404__text {
  text-align: center;
  display: block;
  width: 100%;
  color: var(--primary-color);
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 30px;
}
.page-404__btn {
  width: 100%;
  height: 50px;
  border-radius: 12px;
  background-color: #ff9700;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: var(--title-color);
  font-size: 14px;
  position: relative;
  z-index: 2;
  text-transform: uppercase;
  font-family: var(--head-font);
  font-weight: 500;
  margin: 0 auto;
}
.page-404__btn:hover {
  color: var(--title-color);
  background-color: var(--border-color);
}
@media (min-width: 576px) {
  .page-404__content {
    padding: 40px;
  }
}
@media (max-width: 577px) {
  /* .header__profile-btn{
  display: none;
} */
}
/*==============================
  Modal
==============================*/
.modal {
  position: relative;
  display: block;
  background-color: #16151a;
  margin: 40px auto;
  width: 100%;
  max-width: 420px;
  padding: 20px;
  border-radius: 16px;
  overflow: hidden;
}
.modal--form {
  padding: 20px 20px 5px;
}
.modal--asset {
  max-width: 1300px;
  padding: 35px 20px 20px;
}
.modal .mfp-close {
  display: none;
}
.modal__close {
  position: absolute;
  top: 15px;
  right: 15px;
  z-index: 1;
}
.modal__close svg {
  fill: #bdbdbd;
  opacity: 0.6;
  transition: 0.5s ease;
  height: auto;
  width: 24px;
}
.modal__close:hover svg {
  opacity: 1;
  fill: #ff9700;
}
@media (min-width: 768px) {
  .modal {
    padding: 40px;
  }
  .modal--asset {
    padding: 10px 40px 40px;
  }
  .modal--form {
    padding: 40px 40px 25px;
  }
  .modal--form .modal__close {
    top: 37px;
    right: 35px;
  }
  .modal--form .sign__btn {
    margin-top: 30px;
  }
}
.mfp-bg {
  background: rgba(34, 34, 39, 0.75);
}
.mfp-container {
  padding: 0 15px !important;
}
.my-mfp-zoom-in .zoom-anim-dialog {
  opacity: 0;
  transition: all 0.4s ease-in-out;
  transform: scale(0.8);
}
.my-mfp-zoom-in.mfp-ready .zoom-anim-dialog {
  opacity: 1;
  transform: scale(1);
}
.my-mfp-zoom-in.mfp-ready.mfp-bg {
  opacity: 1;
}
.my-mfp-zoom-in.mfp-removing .zoom-anim-dialog {
  transform: scale(0.8);
  opacity: 0;
}
.my-mfp-zoom-in.mfp-removing.mfp-bg {
  opacity: 0;
}
.my-mfp-zoom-in.mfp-bg {
  opacity: 0;
  transition: opacity 0.4s ease-out;
}
.mfp-iframe-scaler iframe {
  border-radius: 12px;
  box-shadow: none;
  background-color: #16151a;
}
.mfp-iframe-holder .mfp-close {
  color: transparent;
  transition: opacity 0.4s;
}
.mfp-iframe-holder .mfp-close:before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  width: 30px;
  height: 44px;
  background: url('/img/close.svg') no-repeat center;
  background-size: 30px auto;
}
.mfp-iframe-holder .mfp-content {
  max-width: 910px;
}
.mfp-figure::after {
  box-shadow: none;
  background-color: var(--border-color);
}
.mfp-fade.mfp-bg {
  opacity: 0;
  transition: all 0.4s ease-in-out;
}
.mfp-fade.mfp-bg.mfp-ready {
  opacity: 1;
}
.mfp-fade.mfp-bg.mfp-ready .mfp-content {
  opacity: 1;
}
.mfp-fade.mfp-bg.mfp-removing {
  opacity: 0;
}
.mfp-fade.mfp-bg.mfp-removing .mfp-content {
  opacity: 0;
}
.mfp-fade.mfp-wrap .mfp-content {
  opacity: 0;
  transition: all 0.4s ease-in-out;
}
.mfp-fade.mfp-wrap.mfp-ready .mfp-content {
  opacity: 1;
}
.mfp-fade.mfp-wrap.mfp-removing .mfp-content {
  opacity: 0;
}

.followers_list_con {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}
.followers_list_username {
  display: block;
  font-size: 16px;
  color: #b1b1b1;
  font-weight: 500;
}
.followers_list_btn {
  margin-left: auto;
  width: 90px;
  height: 36px;
  font-size: 11px;
  font-weight: 500;
}

.followers-list-img {
  margin: 0px;
  display: block;
  width: 52px;
  height: 52px;
  border-radius: 50%;
  margin-right: 15px;
  margin-top: 10px !important;
}
.followers_list_content {
  max-width: 50%;
}
.followers_list_title {
  font-weight: 550;
  font-size: 15px;
}
.text_in_one_line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.author__nickname {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.dialog-titlebar {
  padding: 25px 30px 25px 30px;
}

.dialog-title {
  font-size: 30px;
  font-weight: 600;
}

.dialog-close {
  position: absolute;
  border-radius: 9999px;
  border-style: solid;
  border-color: var(--title-color);
  top: -5px;
  right: 2px;
  z-index: 999;
}
.dialog-titlebar-medium {
  padding: 15px 30px 15px 30px;
}
.delete_box_padding {
  padding: 0px 30px 10px 30px;
}
.dialog-title-medium {
  font-size: 20px;
  font-weight: 600;
}
.dialog-close-medium {
  position: absolute;
  border-radius: 9999px;
  border-style: solid;
  border-color: var(--title-color);
  top: 5px;
  right: 20px;
}
.dialog__btn {
  width: 100%;
  height: 50px;
  border-radius: 10px;
  background-color: var(--button-bg-color);
  color: var(--button-color);
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  position: relative;
  text-transform: uppercase;
  font-family: var(--head-font);
  font-weight: 500;
}
.dialog__btn:hover {
  background-color: #fff;
  color: #ff9700;
  border: solid 1px #ff9700;
}
.dialog__btn--white {
  background-color: #fff;
  color: #ff9700;
  border: solid 1px #ff9700;
}
.dialog__btn--white:hover {
  color: var(--button-bg-color);
}

.wallet_sel_dialog__btn {
  width: 100%;
  height: 50px;
  border-radius: 10px;
  background-color: var(--button-color);
  color: var(--button-bg-color);
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  position: relative;
  text-transform: uppercase;
  font-family: var(--head-font);
  font-weight: 500;
  border: solid 1px #ff9700;
}
.wallet_sel_dialog__btn:hover {
  background-color: var(--button-bg-color);
  color: var(--button-color);
}

.wallet_selected_dialog__btn {
  background-color: var(--button-bg-color);
  color: var(--button-color);
}

/*==============================
  Blog styles
==============================*/
.blog__banner {
  background: rgb(255, 255, 255);
}

/* .blog__main__title {
  margin-left: 2rem;
} */

.blog__main__title h1 {
  color: var(--title-color);
  font-family: var(--head-font);
  font-size: 26px;
  font-weight: 600;
  text-transform: uppercase;
}

.blog__main__title p {
  color: var(--primary-color);
  font-family: var(--head-font);
  font-size: 16px;
}

.blog__article__title h3 {
  color: var(--primary-color);
  font-family: var(--head-font);
  font-size: 24px;
  font-weight: 600;
  /* text-transform: uppercase; */
  margin-top: 20px;
}
.blog__article__title a h3:hover {
  color: var(--orange-color);
}
.blog__article__icons {
  margin-top: 20px;
}

.blog__article__icons a {
  color: gray;
  font-family: var(--head-font);
  font-size: 16px;
  font-weight: 400;
  margin-top: 5px;
  margin-right: 0px;
  width: 100%;
}

.blog__article__text {
  font-family: var(--text-font);
  margin-bottom: 30px;
}

.blog__article__text p {
  color: var(--primary-color);
  font-size: 16px;
  font-weight: 400;
  margin-top: 30px;
}

.image-gallery-content .image-gallery-slide .image-gallery-image {
  max-height: 100% !important;
}
.blog__sidebar__item {
  width: 100%;
  margin-bottom: 3rem;
  padding-bottom: 3rem;
  border-bottom: 1px solid var(--border-color);
}

.blog__sidebar__title {
  width: 100%;
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
  margin-bottom: 20px;
}
.blog__side--text {
  font-size: 16px;
  max-width: calc(100% - 25px);
}
.blog__side--text a {
  color: #80d0d0;
}
.blog__side--text h8 {
  font-size: 14px;
  color: gray;
}

.blog__follow {
  width: 200px;
  height: 42px;
  border-radius: 12px;
  background-color: #ff9700;
  color: white;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 13px;
  position: relative;
  z-index: 2;
  text-transform: uppercase;
  font-family: 'Inter', sans-serif;
  font-weight: 500;
  margin-left: auto;
  margin-right: 0px;
}

.blog__article__image {
  padding-bottom: 10px;
}
.blog_readmore__btn {
  width: 200px;
  height: 50px;
  border-radius: 12px;
  background-color: #ff9700;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: var(--button-color);
  font-size: 14px;
  position: relative;
  z-index: 2;
  text-transform: uppercase;
  font-family: var(--head-font);
  font-weight: 500;
  margin: auto;
}
.blog_readmore__btn:hover {
  color: var(--button-color-hover);
  background-color: var(--button-bg-color-hover);
}

.blogger_icon {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  position: relative;
}

.blog__published {
  padding-top: 10px;
  font-family: var(--head-font);
  color: #808080;
}

@media (min-width: 768px) {
  .blog__main__title {
    margin-left: 0px;
  }
  .blog__article__icons a {
    margin-right: 30px;
    width: auto;
  }
  .blog__article__text {
    margin-bottom: 0px;
  }
  .delete_box_padding {
    padding: 0px 30px 30px 30px;
  }
}
@media (min-width: 1200px) {
  .blog__banner__content {
    margin: auto;
    padding: 50px 0 50px 0;
  }
}
@media (min-width: 1440px) {
  .blog__banner__content {
    margin: auto;
    padding: 50px 0 50px 0;
  }
}

/*==============================
  Hamburger styles
==============================*/
#page-wrap {
  text-align: center;
  overflow: auto;
  top: 45%;
  position: relative;
}
.hamburger__menu {
  display: block;
}

@media (min-width: 1200px) {
  .hamburger__menu {
    display: none;
  }
  .active_nav_link {
    border-bottom: 2px solid var(--button-bg-color);
  }
  .header__nav-link:hover {
    border-bottom: 2px solid var(--button-bg-color);
  }
}
.bm-item {
  text-decoration: none;
  margin-bottom: 10px;
  color: var(--title-color);
  transition: color 0.2s;
  font-family: var(--head-font);
}

.bm-item:hover {
  color: var(--orange-color);
}

.bm-burger-button {
  position: inline-block;
  color: var(--button-bg-color);
  width: 36px;
  height: 20px;
  right: 36px;
  top: 36px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: var(--orange-color);
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
}

/* General sidebar styles */
.bm-menu {
  background: #fff;
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
}

.bm-menu-wrap {
  position: absolute !important;
  width: 160px !important;
  height: 310px !important;
  right: 0px !important;
  top: 45px !important;
}
/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
  left: 0px;
  top: 0px;
}

.Toastify__toast-container {
  z-index: 2147483641 !important;
}

@media only screen and (max-width: 480px) {
  .Toastify__toast-container--bottom-left,
  .Toastify__toast-container--bottom-center,
  .Toastify__toast-container--bottom-right {
    width: calc(100% - 20px);
    margin: 10px;
  }
}

.no__result__alert {
  text-align: center;
  margin: auto;
  padding-top: 30px;
  font-size: 20px;
  font-family: var(--text-font);
}

@media (max-width: 1024px) {
  .steps-content-wrapper .steps_content_list_box {
    width: fit-content;
  }
}

@media (max-width: 768px) {
  .home__content {
    padding: 30px 10px;
    padding-top: 40px;
  }
  .remove-px4 {
    padding-left: 0px !important;
    padding-right: 0px;
  }

  /*  */
  .widget_dialog_content {
    min-height: fit-content;
    max-height: 100%;
    min-width: 100%;
  }
  .steps-content {
    margin-top: 5px;
  }
  .steps-content-wrapper .steps_content_list_box {
    width: 25%;
  }
  .steps-content-wrapper .steps-content {
    width: 75%;
  }
  .author__social a {
    margin-right: 6px;
  }
  .chip {
    padding: 5px 8px !important;
  }
  .nft_image_skeleton-wrapper {
    max-height: unset;
  }
  .nft_image_skeleton-wrapper .img_class_box {
    position: relative;
    -webkit-transform: translateZ(0);
    -moz-transform: translateZ(0);
    -ms-transform: translateZ(0);
    -o-transform: translateZ(0);
    transform: translateZ(0);
    perspective: 1000;
  }

  /* .ant-steps-vertical.ant-steps-small .ant-steps-item-container .ant-steps-item-title {
    width: 112px;
  } */
}

/*  */

* {
  min-height: 0px;
  /* min-width: 0px; */
  object-fit: cover;
}

slick-slider,
slick-initialized {
  overflow: hidden !important;
  width: 100% !important;
}

.MuiTabs-scrollButtonsDesktop {
  display: block !important;
  margin: auto !important;
  text-align: center !important;
}
@media (max-width: 639px) {
  .footer_logo-img-2 {
    margin-left: 15px;
    margin-right: 15px;
  }
  .footer_sm_img_wrapper {
    grid-gap: 0px;
  }
  .nft_image_skeleton-wrapper {
    max-height: unset;
  }
  .inside-new {
    margin-top: 40px;
  }
}

@media (max-width: 530px) {
  .collection_main_box_container {
    padding: 20px 0 !important;
  }
  .royalty_btn_box {
    width: 100%;
    flex-direction: column;
    grid-gap: 10px;
  }
  .royalty__btn {
    width: unset;
  }

  .ant-steps-vertical > .ant-steps-item .ant-steps-item-icon {
    margin-right: 5px !important;
  }
  .steps-content {
    margin-top: 5px;
  }
  .profile_text_wrapper {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
  .ant-steps-item-tail {
    position: absolute;
    top: -4px !important;
    left: 25px !important;
    width: 60% !important;
    padding: 0 10px !important;
  }

  .step_title-box .ant-steps-item-content {
    padding-right: 0px;
  }
  .steps-content-wrapper {
    flex-wrap: wrap;
  }
  .steps-content-wrapper {
    align-items: unset;
  }
  .steps-content-wrapper .steps_content_list_box {
    margin-top: 15px;
    width: 100%;
  }
  .steps-content-wrapper .steps-content {
    width: 100%;
  }
  .featured_nft_ft {
    margin-left: 0;
    margin-bottom: 0;
    margin-top: 5px;
  }
  .featured_nft_ft-select {
    margin: 0;
  }

  .featured_nft_ft {
    margin-left: 0;
    margin-bottom: 0;
    margin-top: 5px;
  }
  .featured_nft_ft-select {
    margin: 0;
  }
  .footer_sm_img_wrapper {
    position: relative;
  }

  /* .footer_logo-img-2 {
    position: absolute;
    top: 45px;
    left: 50%;
    transform: translateX(-50%);
  } */
  /* .footer_logo-img-2 img {
    width: 130px;
    height: 20px;
  } */
  .main_title-box {
    margin-top: 55px !important;
  }
  .inner_box_center_wrapper {
    margin-bottom: 20px;
  }
  .contact_form-wrapper {
    width: 100%;
  }
  .author__social a {
    margin-right: 25px;
  }
  .nft_image_skeleton-wrapper {
    max-height: unset;
  }
  .nft_image_skeleton-wrapper .img_class_box {
    position: relative;
    -webkit-transform: translateZ(0);
    -moz-transform: translateZ(0);
    -ms-transform: translateZ(0);
    -o-transform: translateZ(0);
    transform: translateZ(0);
    perspective: 1000;
  }
}

@media (max-width: 320px) {
  .ant-steps-vertical > .ant-steps-item .ant-steps-item-title {
    font-size: 11px;
  }
  .home_btn-bn {
    margin-right: 0px;
  }
  .featured_nft {
    width: 260px;
  }
  .nft_image_skeleton-wrapper .img_class_box {
    position: relative;
    -webkit-transform: translateZ(0);
    -moz-transform: translateZ(0);
    -ms-transform: translateZ(0);
    -o-transform: translateZ(0);
    transform: translateZ(0);
    perspective: 1000;
  }
}
.featured_nft {
  height: auto !important;
}
.featured_nft_autoh {
  height: 100% !important;
}
@media (max-width: 300px) {
  .collection_main_box {
    height: 350px;
  }
  .featured_nft {
    width: 220px;
  }
  .featured_img {
    height: 220px;
  }
  .card {
    margin: 30px 0px 10px 0px;
  }
}

@media (max-width: 2300px) {
  .home {
    /* min-height: calc(100vh - 70px); */
  }
}
.featured_card__author img {
  display: block;
  overflow: hidden;
  width: 40px !important;
  height: 40px !important;
  border-radius: 10px;
  margin-right: 10px;
  object-fit: cover !important;
}
.cookies-banner {
  border-top: 2px solid #ff9700;
}
.cookies-banner div {
  display: flex;
}
.cookies-banner div button#rcc-decline-button {
  text-transform: uppercase;
  background: white !important;
  color: var(--orange-color) !important;
  border: 1px solid var(--orange-color) !important;
}
.cookies-banner div button {
  display: block;
  white-space: nowrap;
  margin-right: 30px !important;
  transition: color 0.5s ease;
  color: white !important;
  background-color: var(--orange-color) !important;
  border-radius: 12px !important;
  padding: 11px 20px !important;
}

.wrap-line {
  margin: 20px 0 50px 0;
  position: relative;
  color: white;
}
.wrap-line:before {
  position: absolute;
  width: 40px;
  height: 1px;
  bottom: -30px;
  left: 50%;

  margin-left: -20px;
  content: '';
  border-bottom: 1px solid;
}
.wrap-line h3 span {
  font-weight: 200;
}
.link-hovering:hover {
  text-decoration: underline;
}
.wrap-line h3 {
  font-family: 'Roboto', Helvetica, Arial, sans-serif;
  font-size: 26px;
  font-weight: 600;
  line-height: 1.5;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  margin-top: 0;
  margin-bottom: 0;
  color: white;
  text-align: center;
}

.footer-newsletter-pop {
  background-color: transparent;
  color: white;
  padding-bottom: 00;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 2px solid white;
  padding-left: 0;
  outline: #9acd3200;
  width: 70%;
}
.footer-newsletter-pop::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: rgb(235, 206, 206);

  opacity: 1; /* Firefox */
}
.join-footer {
  font-family: 'Nanum Myeongjo', serif;
  font-size: 28px;
  font-weight: 400;
  line-height: 1.5;
  margin-bottom: 0;
  color: #fff;
  text-align: center;
  padding: 26px;
}
.newer-join-btn {
  margin-left: 25px;
  color: #fff;
  background-color: #f16604;
  border: 2px solid #fff;
  /* box-shadow: 0px 0px 4px black; */
  padding: 10px;
  margin-top: 24px;
}

/* //prefect scroll bar */
.ps__rail-x,
.ps__rail-y {
  opacity: 0.6;
}

.ant-dropdown-trigger {
  position: relative;
  padding: 0 6px 0 6px;
  height: 45px;
  font-family: var(--head-font);
  border: 1px solid var(--border-color);
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}
/* .swiper-button-next:after, .swiper-button-prev:after{
  display: none;
} */
.swiper-button-next {
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
}

.swiper-button-next::after {
  content: '\f30b' !important;
  font-family: 'Font Awesome 5 Free' !important;
  font-weight: 900 !important;
  color: white !important;
}
.swiper-button-prev::after {
  content: '\f30a' !important;
  font-family: 'Font Awesome 5 Free' !important;
  font-weight: 900 !important;
  color: white !important;
}
@media only screen and (max-width: 767px) {
  .footer_sm_img_wrapper {
    flex-wrap: wrap;
  }
  /* .footer_logo-img-2 {
    margin-right: 0px;
    margin-bottom: 15px;
  } */
  .footer_sm_img_wrapper img {
    width: 85px;
  }
  .staslogo-inner {
    margin-left: -10px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 823px) {
  .card__price span:last-child {
    font-size: 12px !important;
  }
}
@media only screen and (max-width: 325px) {
  .card__price span:last-child {
    font-size: 11px !important;
  }
  .bsv_size_size {
    font-size: 12px !important;
  }
}
@media only screen and (max-width: 360px) {
  .header__action-btn--signin {
    min-width: 70px;
  }
  .header__logo img {
    width: 150px;
    object-fit: contain;
  }
  .header__action {
    margin-left: 10px;
  }
}
[type='search']:focus {
  border-color: transparent;
  --tw-ring-color: transparent;
  --tw-ring-shadow: transparent;
}

.card__author.featured_card__author {
  width: 45px;
}
.featured_card__author img {
  margin-right: 0px;
}
.featured_nft_text {
  padding-left: 15px;
}

/* discovers */
.fontmain {
  font-size: 15px !important;
}

.linkcookie {
  color: #ff9700 !important;
}
.sharebutton {
  border: none !important;
}

.cnw-logo img {
  display: inline-block;
  width: 120px;
}
.cnw-logo {
  display: flex;
  justify-content: center;

  position: relative;
}
.cnw-logo {
  padding-top: 20px;
}
/* .cnw-logo::before {
  position: absolute;
  content: "";
  top: 0;
  height: 1px;
  background-color: #fff;
  width: 200px;
} */
.artist_profile_details img {
  width: 40px;
}
@media (max-width: 581px) {
  .width-custom {
    width: 100%;
  }
  .copy_icn .share__link {
    height: 33px;
    width: 50px;
    line-height: 25px;
  }
}

.filter__checkboxes li {
  display: flex;
}
@media (min-width: 450px) {
  .footer-logo-bottom img {
    margin: 0px 15px;
  }
}
.nft-numbering {
  position: absolute;
  right: 30px;
  bottom: 50px;
  font-size: 16px;
}
.nft-numbering-collection {
  font-size: 16px;
}
.numbering {
  position: absolute;
  bottom: 30px;
  left: 30px;
  font-size: 18px;
}
.MuiDialog-root {
  z-index: 999 !important;
}

.ril__toolbar {
  background-color: transparent;
}

@media only screen and (max-width: 1186px) {
  .sign_up_button {
    display: none !important;
  }
}
.custom_check input:not(:checked),
.custom_check input:checked {
  position: absolute;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 24px;
  opacity: 0;
  cursor: pointer;
  top: 0;
}
.custom_check a {
  position: relative;
  z-index: 9;
}
.alert__button {
  display: flex;
  align-items: center;
  flex-direction: row;
}
.alert__button button {
  width: 100%;
  min-width: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 2px;
}
.alert__button button span {
  width: 100%;
  display: block;
}

@media only screen and (max-width: 768px) {
  .alert__button {
    flex-direction: column;
  }
}
.salesTab {
  border-bottom: 1px solid #e4e4e7;
}
.salesTab .MuiButtonBase-root {
  color: #1890ff;
  width: 116.391px;
  font-weight: 500;
  text-transform: none;
  min-width: 130px !important;
  background-color: white;
}
.input_custom {
  height: auto !important;
}

.statistics .ant-dropdown-trigger {
  height: 0px;
  border: none;
}

.statistics .ant-table-filter-column {
  align-items: center;
}

.MuiDialog-Transaction .MuiDialogContent-root {
  overflow-y: inherit;
}

/* Light Gallery CSS START*/
.lg-backdrop.in {
  opacity: 0.85 !important;
}
/* .lg-outer .lg-image {
  width: 500px !important;
} */
.lg-toolbar {
  background-color: transparent;
}
/* Light Gallery CSS END */
.ymessage {
  padding: 0 !important;
}
/* .border{
  border-top: 2px solid #ff9700;
} */

.ymessage div {
  font-size: 14px;
  font-weight: bold;
}

.collection-AntTabs .asset__tabs {
  margin-top: 0px;
}

.MuiDialog-Transaction .MuiDialog-scrollPaper .MuiDialog-paperScrollPaper {
  max-height: 450px !important;
}

/*working on scroll arrow in my collection, when zoom from 90% to 100%*/
/* @media (min-width: 1200px) {
  .MuiTabScrollButton-root {
    width: 20px !important;
    opacity: 0 !important;
  }
} */
.close_icon_notification {
  position: absolute;
  right: 0;
  top: 14px;
  transform: translate(-10px, 0px);
}

input[type='text'],
input[type='number'],
textarea {
  font-size: 16px;
}

.adminPanel .header.top-11 {
  top: 0;
}
.header.top-11 {
  top: 52px;
}
/* @media (max-width: 915px) {
  .header.top-11 {
    top: 52px;
  }
}
@media (max-width: 872px) {
  .header.top-11 {
    top: 80px;
  }
}
@media (max-width: 639px) {
  .header.top-11 {
    top: 56px;
  }
}
@media (max-width: 460px) {
  .header.top-11 {
    top: 72px;
  }
}
@media (max-width: 335px) {
  .header.top-11 {
    top: 88px;
  }
} */

/* Artist tab */
.ArtistTab .MuiTabs-root {
  position: unset;
}

/* react-toastify */
.Toastify__close-button {
  padding-right: 7px;
}

/*To fix the dropdown menu in AppNavbar*/
.ant-dropdown-placement-bottom {
  position: fixed;
}

/* //audio player styles overwrite */
.react-jinke-music-player-mobile-toggle {
  display: flex !important;
  justify-content: center !important;
}
.react-jinke-music-player-mobile {
  z-index: 9999 !important;
}
.audio-lists-panel-mobile.show {
  z-index: 99999 !important;
}
.react-jinke-music-player-mobile-operation .items .item {
  display: flex;
  justify-content: center;
}
.react-jinke-music-player-main
  .music-player-panel
  .panel-content
  .rc-slider-handle,
.react-jinke-music-player-main
  .music-player-panel
  .panel-content
  .rc-slider-track {
  background-color: #ff972b !important;
}

.react-jinke-music-player-main.light-theme svg {
  color: #ff972b !important;
}

.react-jinke-music-player-main.light-theme .rc-switch-checked {
  background-color: #ff972b !important;
  border: 1px solid #ff972b !important;
}

.react-jinke-music-player-main svg:active,
.react-jinke-music-player-main svg:hover {
  color: #ff972b !important;
}

.audio-lists-panel-content .audio-item.playing,
.audio-lists-panel-content .audio-item.playing svg {
  color: #ff972b !important;
}

.react-jinke-music-player .music-player-controller {
  color: #ff972b !important;
}
.react-jinke-music-player
  .music-player-controller
  .music-player-controller-setting {
  background: #ff992b6c !important;
}
.react-jinke-music-player-main.light-theme
  .audio-lists-panel
  .audio-item.playing,
.react-jinke-music-player-main.light-theme
  .audio-lists-panel
  .audio-item.playing
  svg {
  color: #ff972b !important;
}

.react-jinke-music-player-main.light-theme .audio-item.playing .player-singer {
  color: #ff972b !important;
}

.react-jinke-music-player-mobile-progress .rc-slider-handle,
.react-jinke-music-player-mobile-progress .rc-slider-track {
  background-color: #ff972b;
}
.audio-lists-panel-header-close-btn,
.audio-lists-panel-header-delete-btn {
  color: #ff972b !important;
}
.ant-modal-wrap {
  z-index: 9999 !important;
}

@media screen and (max-width: 600px) {
  .create__coupon {
    margin-left: 5px !important;
  }
}

iframe {
  z-index: 9999999 !important;
}

/* ANTD Share overwrite */
.ant-dropdown {
  z-index: 900 !important;
}

/* ANTD Auction Calender */
.ant-picker-dropdown {
  z-index: 9999999 !important;
}

.ant-select-dropdown.ant-select-dropdown-placement-bottomLeft {
  z-index: 15000 !important;
}

/* Edit Artist Details Dialog */
.ArtistDetails_Dialog {
  z-index: 1002 !important;
}
.MuiDialog-root {
  z-index: 9999 !important;
}

.header__image * {
  object-fit: contain !important;
}

.ant-tooltip {
  pointer-events: none;
  z-index: 9999;
}
.wrapWords {
  overflow-wrap: break-word;
}

.react-jinke-music-player-main
  .music-player-panel
  .panel-content
  .player-content
  > .audio-download {
  display: none;
}

#lg-actual-size {
  display: none;
}

@media (max-width: 500px) {
  .ant-picker-dropdown-placement-topLeft .ant-picker-range-arrow {
    display: none;
  }
}

.MuiTabs-flexContainer,
.jss4,
.MuiTab-textColorInherit.Mui-selected,
.MuiTab-textColorInherit {
  background: #ffffff;
}

.startDate {
  background-color: var(--border-color);
  border: 1px solid var(--border-color) !important;
  border-radius: 12px;
  width: 150px;
  margin: 2px !important;
}
.endDate {
  background-color: var(--border-color);
  border: 1px solid var(--border-color) !important;
  border-radius: 12px;
  margin: 2px !important;
  width: 150px;
}
input[type='text'] {
  font-size: 14px;
}
.MuiTabs-root .MuiTab-root {
  text-transform: capitalize;
}
.ant-select-dropdown {
  z-index: 9999;
}

@media (max-width: 432px) {
  .multi_select_con {
    flex-direction: column;
    width: 100%;
  }
  .startDate {
    width: 100%;
  }
  .endDate {
    width: 100%;
  }
}

.MuiFormLabel-root {
  /* line-height: 1.5rem !important; */
  margin-left: 5px;
}

.closeButton {
  position: relative;
  bottom: 3px;
}

@media screen and (max-width: 540px) {
  .test {
    height: 90% !important;
  }
}

.update-button .ant-btn-loading-icon {
  display: flex;
  justify-self: center;
  align-self: center;
}
.delete-coupon {
  z-index: 100 !important;
}

/* About Start */

.about-main-container {
  background-color: rgba(16, 18, 18);
  padding-bottom: 25px;
}

.about-cover {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 100vh;
  width: 100vw;
  max-width: 100%; /* added */
  mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
  position: relative;
  min-width: 200px;
}

.about-stack {
  width: 100%;
  position: relative;
}
.about-1-stack {
  bottom: 150px;
  margin-bottom: -150px;
}
.about-2-stack {
  bottom: 300px;
  margin-bottom: -300px;
}
.about-3-stack {
  bottom: 400px;
  margin-bottom: -400px;
}
.about-4-stack {
  /* margin-bottom: 400px; */
  margin-top: 20px;
  margin-bottom: 20px;
}

.about-5-stack {
  bottom: 150px;
  margin-bottom: -150px;
}

.about-6-stack {
  bottom: 400px;
  margin-bottom: -400px;
}
.about-7-stack {
  bottom: 250px;
  margin-bottom: -250px;
}
.about-title {
  width: 100%;
  font-size: 34px;
  word-wrap: break-word;
  color: #fff0e6;
  font-weight: 600;
  font-style: normal;
  line-height: 1.3;
}

.about-description {
  color: #d6d6d6;
  width: 100%;
  font-weight: 400 !important;
  font-style: normal !important;
  font-size: 20px !important;
}

.about-description-4 {
  width: 100% !important;
}

.about-title-4 {
  width: 100%;
}

.about-footer {
  color: #fff0e6;
  font-weight: 600 !important;
  font-style: normal !important;
  font-size: 20px !important;
  width: 100%;
}
.about-label-text {
  color: #fff0e6;
  font-weight: 550 !important;
  font-style: normal !important;
  font-size: 22px !important;
  width: 100%;
  text-align: center;
  margin-top: 10px !important;
  margin-bottom: 20px !important;
}

.about-1-image {
  background-image: url(/img/about/MA-Background-01.jpg);
  background-position: 80% 15%;
  height: 90vh;
}

.about-2-image {
  background-image: url(/img/about/MA-Background-02.jpg);
  background-position: 30% 10%;
}

.about-3-image {
  background-image: url(/img/about/MA-Background-03.jpg);
  background-position: 55% 0%;
}

.about-5-image {
  background-image: url(/img/about/MA-Background-05.jpg);
  background-position: 65% 10%;
}
.about-6-image {
  background-image: url(/img/about/MA-Background-06.jpg);
  background-position: 40% 5%;
}

.about-7-image {
  background-image: url(/img/about/MA-Background-07.jpg);
}

.about-4-title {
  color: #fff0e6;
  font-size: 60.8px !important;
  font-weight: 600 !important;
  font-style: normal !important;
}

.about-4-description {
  color: #d6d6d6;
  width: 70%;
  font-weight: 400 !important;
  font-style: normal !important;
  font-size: 28.8px !important;
}

.about-4-sub-description {
  color: #fff0e6;
  width: 65%;
  font-weight: 500 !important;
  font-style: normal !important;
  font-size: 28.8px !important;
}

.about-4-footer {
  color: #fff0e6;
  width: 45%;
  font-weight: 300 !important;
  font-style: italic !important;
  font-size: 16px !important;
}

.about-7-button {
  background-color: #ff9700 !important;
  width: 240px;
  height: 40px;
  border-radius: 8px;
  font-weight: 600 !important;
  font-style: normal !important;
  font-size: 17.6px;
  display: flex;
  align-items: center;
  text-transform: initial;
}

.footer_note {
  color: #fff0e6;
  font-weight: 300;
  font-style: italic;
  width: 90%;
  font-size: 12px;
}

@media only screen and (min-width: 2000px) and (max-width: 3000px) {
  .stack-image {
    align-items: center;
  }

  .about-4-footer {
    width: 100%;
    font-size: 28px !important;
  }
}

/* // */
@media only screen and (min-width: 768px) {
  .about-1-image {
    background-position: 75% 5%;
  }

  .about-2-image {
    background-position: 5% 10%;
  }
  .about-3-image {
    background-position: 35% 0%;
  }
  .about-5-image {
    background-position: 65% 10%;
  }
  .about-1-stack {
    padding-left: 64px;
    padding-bottom: 64px;
    bottom: 350px;
    margin-bottom: -350px;
  }
  .about-2-stack {
    padding-left: 64px;
    padding-bottom: 64px;
    bottom: 250px;
    margin-bottom: -250px;
  }
  .about-3-stack {
    padding-left: 64px;
    padding-bottom: 64px;
    bottom: 350px;
    margin-bottom: -350px;
  }
  .about-4-stack {
    padding-left: 64px;
    padding-bottom: 64px;
  }
  .about-5-stack {
    padding-left: 64px;
    padding-bottom: 64px;
    bottom: 350px;
    margin-bottom: -350px;
  }
  .about-6-stack {
    padding-left: 64px;
    padding-bottom: 64px;
    bottom: 350px;
    margin-bottom: -350px;
  }

  .about-7-stack {
    padding-left: 64px;
    padding-bottom: 64px;
    bottom: 200px;
    margin-bottom: -200px;
  }

  .about-title {
    width: 60%;
    font-size: 42px !important;
  }

  .about-description {
    width: 60%;
    font-size: 22px !important;
  }

  .about-footer {
    font-size: 22px !important;
    width: 60%;
  }

  .about-label-text {
    font-size: 26px !important;
  }

  .footer_note {
    width: 45%;
    font-size: 16px !important;
  }
}

@media only screen and (min-width: 1400px) {
  .about-1-stack {
    bottom: 450px;
    margin-bottom: -450px;
  }

  .about-2-stack {
    bottom: 350px;
    margin-bottom: -350px;
  }
  .about-3-stack {
    bottom: 450px;
    margin-bottom: -450px;
  }
  .about-5-stack {
    bottom: 450px;
    margin-bottom: -450px;
  }
  .about-6-stack {
    bottom: 450px;
    margin-bottom: -450px;
  }
  .about-7-stack {
    bottom: 300px;
    margin-bottom: -300px;
  }

  .about-title {
    width: 60%;
    font-size: 54px !important;
  }

  .about-description {
    width: 60%;
    font-size: 26px !important;
  }

  .about-footer {
    font-size: 26px !important;
  }
  .about-label-text {
    font-size: 30px !important;
  }
}

@media only screen and (min-width: 2000px) {
  .about-1-stack {
    bottom: 550px;
    margin-bottom: -550px;
  }
  .about-2-stack {
    bottom: 450px;
    margin-bottom: -450px;
  }
  .about-3-stack {
    bottom: 550px;
    margin-bottom: -550px;
  }
  .about-5-stack {
    bottom: 550px;
    margin-bottom: -550px;
  }
  .about-6-stack {
    bottom: 550px;
    margin-bottom: -550px;
  }
  .about-7-stack {
    bottom: 450px;
    margin-bottom: -450px;
  }
}
/* About End */
