@media (min-width: 320px) and (max-width: 413px) {
  .turning-screens {
    width: 100%;
  }
  .resp-footer{
    margin-top:10px;
  }

  .inside-new{
    width: 90%;
  }
 
.link-flex-res{
  display:flex!important;
  justify-content: center;
}

.about__text{
  padding: 0 0 0 0!important;
}
.resp-ters{
  margin-top: 50px!important;
}

  .turning-screens-discover {
    width: 100%;
  }
  .turning-screens-artist {
    width: 100%;
  }

  .card_padding {
    padding: 0px 3px 0px 1px;
  }
  .card__likes {
    margin-top: 5px;
  }
  .card_padding {
    padding: 0px 0px 0px 6px;
  }
  .cookies-banner div {
    text-align: center;
    display: -moz-box;
    display: flex;
    margin: 9px;
    justify-content: center;
    width: 100%;
  }
  .cookies-banner div button {
    margin: 15px !important;
  }
  .footer_mark {
    width: 70% !important;
  }
  .footer__links a {
    font-size: 1rem;
  }
  .footer__title {
    font-size: 1rem;
  }
}

@media (max-width: 414px) {
  .sign__btn.max-nft-size-btn,
  .sign__input.max-nft-size-input {
    width: 100%;
  }
}

@media (min-width: 414px) and (max-width: 529px) {
  .turning-screens {
    width: 100%;
  }
  .resp-footer{
    margin-top:10px;
  }

  .about__text {
    padding-left: 8px;
    padding-right: 8px;
}
  .footer_mark {
    width: 60% !important;
  }
  .resp-ters{
    margin-top: 50px!important;
  }
  
  .inside-new{
    width: 70%;
  }
 
.link-flex-res{
  display:flex!important;
  justify-content: center;
}

  .footer__links a {
    font-size: 1rem;
  }
  .footer__title {
    font-size: 1rem;
  }
  .cookies-banner div {
    text-align: center;
    display: -moz-box;
    display: flex;
    margin: 9px;
    justify-content: center;
    width: 100%;
  }
  .cookies-banner div button {
    margin: 15px !important;
  }
  .turning-screens-discover {
    width: 100%;
  }
  .discover-screen1 {
    width: 100%;
  }
  .discover-screen2 {
    width: 100%;
  }
  .discover-screen-artist1 {
    width: 100%;
  }
  .discover-screen-artist2 {
    width: 100%;
  }
  .turning-screens-artist {
    width: 100%;
  }
  .card_padding {
    padding: 0px 0px 0px 6px;
  }
  .card__likes {
    margin-top: 5px;
  }

  .author__meta {
    padding: 0 1px 0px 0px;
  }

  .card__info {
    align-items: center;
  }
  .card_padding {
    padding: 0px 3px 0px 1px;
  }
  .card__likes span {
    margin-right: 2px;
  }
  .author__meta a {
    padding: 0px 0px 0px 0px;
  }
  .author__meta a {
    margin-left: 9px;
  }
  .author__followers {
    flex-direction: column;

    min-width: 29%;
  }
  .author__followers div:first-child {
    margin-right: 10px;
  }
  .author__followers div svg {
    margin-right: 0px !important;
  }
  .author__wrap {
    display: flex;
    flex-direction: row;

    flex-wrap: nowrap;
    padding-top: 15px;
  }
  .author__nickname {
    min-height: 48px;
  }
}

@media (max-width: 639px) {
  .card__category {
    left: 7ch!important;
    transform: translate(-50%);
  }
}

/* for very smaller device */
@media (max-width: 320px) {
  .card__category {
    left: 7ch!important;
  }
}

@media (min-width: 530px) and (max-width: 639px) {
  .turning-screens {
    width: 100%;
  }
  .inside-new{
    width: 60%;
  }
  .resp-footer{
    margin-top:10px;
  }

  .about__text {
    padding-left: 10px;
    padding-right: 10px;
}
  .resp-ters{
    margin-top: 50px!important;
  }
 

.link-flex-res{
  display:flex!important;
  justify-content: center;
}

  .footer_mark {
    width: 60% !important;
  }
  .footer__links a {
    font-size: 1rem;
  }
  .footer__title {
    font-size: 1rem;
  }
  .cookies-banner div {
    text-align: center;
    display: -moz-box;
    display: flex;
    margin: 9px;
    justify-content: center;
    width: 100%;
  }
  .cookies-banner div button {
    margin: 15px !important;
  }
  .turning-screens-discover {
    width: 100%;
  }
  .discover-screen1 {
    width: 100%;
  }
  .discover-screen2 {
    width: 100%;
  }

  .discover-screen-artist1 {
    width: 100%;
  }
  .turning-screens-artist {
    width: 100%;
  }
  .discover-screen-artist2 {
    width: 100%;
  }
  .card_padding {
    padding: 0px 0px 0px 6px;
  }
  .card__likes {
    margin-top: 5px;
  }
  .card_padding {
    padding: 0px 3px 0px 1px;
  }

  .author__meta {
    padding: 0 0px 0px 0px;
  }
  .card__info {
    align-items: center;
  }
  .author__meta a {
    padding: 0px 0px 0px 8px;
  }
  .author__wrap {
    display: flex;
    flex-direction: row;

    flex-wrap: nowrap;
    padding-top: 15px;
  }
}
@media (max-width: 640px) {
  .displayname {
    display: none;
  }
}

@media (min-width: 640px) and (max-width: 1023px) {
  .items-top .items-top1 {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-top: 20px;
  }
  .resp-ters{
    margin-top: 50px!important;
  }
  .about__text {
    padding-left: 18px;
    padding-right: 15px;
}
  .items-top .items-top2 {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 20px;
  }
  .items-top .items-top2 > span,
  .items-top .items-top1 > span {
    width: 135px;
    text-align: start;
  }
  .footer_mark {
    width: 60% !important;
  }
  .footer__links a {
    font-size: 1rem;
  }
  .footer__title {
    font-size: 1rem;
  }
  .items-top .items-top2 > ul,
  .items-top .items-top1 > ul {
    width: 135px;
    text-align: start;
  }
  .items-top .items-top2 {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
}
@media (min-width: 640px) and (max-width: 684px) {
  .turning-screens {
    width: 50%;
  }
  .discover-screen1 {
    width: 50%;
  }
  .discover-screen2 {
    width: 50%;
  }
  .inside-new{
    width: 60%;
  }

  .discover-screen-artist1 {
    width: 50%;
  }
  .discover-screen-artist2 {
    width: 50%;
  }
  .turning-screens-artist {
    width: 100%;
  }
  .turning-screens-discover {
    width: 100%;
  }

  .card__likes {
    margin-top: 5px;
  }

  .card_padding {
    padding: 0px 0px 0px 6px;
  }
  
  .author__meta {
    padding: 0 0px 0px 0px;
  }
  .card__info {
    align-items: center;
  }
  .card__likes span {
    margin-right: 2px;
  }
  .author__meta a {
    padding: 0px 0px 0px 8px;
  }
  .author__wrap {
    display: flex;
    flex-direction: row;

    flex-wrap: nowrap;
    padding-top: 15px;
  }
}
@media (min-width: 685px) and (max-width: 767px) {
  .turning-screens {
    width: 50%;
  }
  .inside-new{
    width: 55%;
  }

  .discover-screen1 {
    width: 50%;
  }
  .discover-screen2 {
    width: 50%;
  }
  .turning-screens-discover {
    width: 100%;
  }
  .discover-screen-artist1 {
    width: 50%;
  }


  .discover-screen-artist2 {
    width: 50%;
  }
  .turning-screens-artist {
    width: 100%;
  }
  .card__likes {
    margin-top: 5px;
  }
  .card_padding {
    padding: 0px 0px 0px 6px;
  }
  .author__meta {
    padding: 0 0px 0px 0px;
  }
  .card__info {
    align-items: center;
  }
  .card__likes span {
    margin-right: 2px;
  }
  .author__meta a {
    padding: 0px 0px 0px 9px;
  }
}
@media (min-width: 768px) and (max-width: 884px) {
  .turning-screens-discover {
    width: 50%;
  }
  .inside-new{
    width: 50%;
  }
  .turning-screens {
    width: 33%;
  }
  .nft-card-response {
    width: 50%;
  }

  .discover-screen1 {
    width: 33%;
  }
  .discover-screen2 {
    width: 67%;
  }
  .author {
    padding: 18px;
    /* margin: 8px; */
  }
  .discover-screen-artist1 {
    width: 33%;
  }
  .discover-screen-artist2 {
    width: 67%;
  }
  .turning-screens-artist {
    width: 50%;
  }
  .author__meta {
    padding: 0 0px 0px 0px;
  }
  .card__likes {
    margin-top: 5px;
  }

  .card__info {
    align-items: center;
  }
  .card_padding {
    padding: 0px 3px 0px 1px;
  }
  .card__likes span {
    margin-right: 2px;
  }
  .author__meta a {
    padding: 0px 0px 0px 8px;
  }
  .author__followers {
    flex-direction: column;
    /* margin: 10px 10px 10px 10px; */
  }
  .author__followers div:first-child {
    margin-right: 5px;
  }

  .admin.author__followers div svg {
    margin-right: 0px !important;
  }
  .author__followers div svg {
    margin-right: 0px !important;
  }
  .author__followers {
    flex-direction: column;
    /* margin: 10px 10px 10px 10px; */
  }
  .author__wrap {
    display: -moz-box;
    display: flex;
    padding-top: 15px;
    /* margin-right: 15px!important; */
    width: auto;
    flex-direction: row;
    padding-left: 0px;
    /* flex-wrap: nowrap; */
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
  .author__wrap div:nth-child(2) {
    margin-right: 0px !important;
    width: auto !important;
  }
}
@media (max-width: 1023px) {
  .newer-join-btn {
    margin-left: 0px !important;
    margin-top: 20px !important;
  }
}
@media (min-width: 885px) and (max-width: 1023px) {
  .author__meta {
    padding: 0 0px 0px 0px;
  }
  .discover-screen1 {
    width: 33%;
  }
  .inside-new{
    width: 45%;
  }
  .turning-screens {
    width: 33%;
  }

  .discover-screen2 {
    width: 67%;
  }
  .turning-screens-discover {
    width: 50%;
  }
  .discover-screen-artist1 {
    width: 33%;
  }
  .discover-screen-artist2 {
    width: 67%;
  }
  .turning-screens-artist {
    width: 50%;
  }
  .card__likes {
    margin-top: 5px;
  }

  .card__info {
    align-items: center;
  }
  .card_padding {
    padding: 0px 3px 0px 1px;
  }
  .card__likes span {
    margin-right: 2px;
  }
  .author__meta a {
    padding: 0px 0px 0px 8px;
  }

  .author__followers div:first-child {
    margin-right: 10px;
  }
  .author__followers div svg {
    margin-right: 0px !important;
  }
  .author__followers {
    flex-direction: column;
    /* margin: 10px 10px 10px 10px; */
  }
  .author__wrap {
    display: -moz-box;
    display: flex;
    padding-top: 15px;
    /* margin-right: 15px!important; */
    width: auto;
    flex-direction: row;
    padding-left: 0px;
    /* flex-wrap: nowrap; */
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
  .author__wrap div:nth-child(2) {
    margin-right: 0px !important;
    width: auto !important;
  }
}
@media (min-width: 1024px) and (max-width: 1199px) {
  .author__meta {
    padding: 0 0px 0px 0px;
  }
  .turning-screens {
    width: 25%;
  }

  .discover-screen1 {
    width: 25%;
  }
  .discover-screen2 {
    width: 75%;
  }

  .turning-screens-discover {
    width: 33.3%;
  }
  .discover-screen-artist1 {
    width: 25%;
  }
  .author {
    padding: 18px;
    margin: 8px;
  }
  .discover-screen-artist2 {
    width: 75%;
  }
  .turning-screens-artist {
    width: 33.3%;
  }

  .off-screens-for-main-only .turning-screens:nth-child(5),
  .off-screens-for-main-only .turning-screens:nth-child(6) {
    display: none !important;
  }
  .coloctors-turning-pff .turning-screens:nth-child(5),
  .coloctors-turning-pff .turning-screens:nth-child(6) {
    display: block !important;
  }

  .card__likes {
    margin-top: 5px;
  }

  .card__info {
    align-items: center;
  }
  .card_padding {
    padding: 0px 3px 0px 1px;
  }
  .card__likes span {
    margin-right: 2px;
  }
  .author__meta a {
    padding: 0px 0px 0px 8px;
  }

  .author__followers div:first-child {
    margin-right: 10px;
  }
  .author__followers div:first-child {
    margin-right: 5px;
  }

  .admin.author__followers div svg {
    margin-right: 0px !important;
  }
  .author__followers div svg {
    margin-right: 0px !important;
  }
  .author__followers {
    flex-direction: column;
    /* margin: 10px 10px 10px 10px; */
  }
  .author__wrap {
    display: -moz-box;
    display: flex;
    padding-top: 15px;
    /* margin-right: 15px!important; */
    width: auto;
    flex-direction: row;
    padding-left: 0px;
    /* flex-wrap: nowrap; */
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
  .author__wrap div:nth-child(2) {
    margin-right: 0px !important;
    width: auto !important;
  }
}

@media (min-width: 1200px) and (max-width: 1366px) {
  .turning-screens {
    width: 25%;
  }
  .discover-screen1 {
    width: 25%;
  }
  .discover-screen2 {
    width: 75%;
  }
  .turning-screens-discover {
    width: 33.3%;
  }
  .discover-screen-artist1 {
    width: 25%;
  }
  .discover-screen-artist2 {
    width: 75%;
  }
  .turning-screens-artist {
    width: 33.3%;
  }
  .off-screens-for-main-only .turning-screens:nth-child(5),
  .off-screens-for-main-only .turning-screens:nth-child(6) {
    display: none !important;
  }
  .coloctors-turning-pff .turning-screens:nth-child(5),
  .coloctors-turning-pff .turning-screens:nth-child(6) {
    display: block !important;
  }

  .author__meta {
    padding: 0 0px 0px 0px;
  }
  .card__likes {
    margin-top: 5px;
  }

  .card__info {
    align-items: center;
  }
  .card_padding {
    padding: 0px 3px 0px 1px;
  }
  .card__likes span {
    margin-right: 2px;
  }
  .author__meta a {
    padding: 0px 0px 0px 8px;
  }

  .author__followers div:first-child {
    margin-right: 10px;
  }
  .author__followers div svg {
    margin-right: 0px !important;
  }
  .author__followers {
    flex-direction: column;
    /* margin: 10px 10px 10px 10px; */
  }
  .author__wrap {
    display: flex;
    flex-direction: row;

    flex-wrap: nowrap;
    padding-top: 15px;
  }
}

@media (min-width: 1367px) and (max-width: 1535px) {
  .turning-screens {
    width: 25%;
  }
  .discover-screen1 {
    width: 25%;
  }
  .discover-screen2 {
    width: 75%;
  }
  .turning-screens-discover {
    width: 33.3%;
  }
  .discover-screen-artist1 {
    width: 25%;
  }
  .discover-screen-artist2 {
    width: 75%;
  }
  .turning-screens-artist {
    width: 33.3%;
  }
  .off-screens-for-main-only .turning-screens:nth-child(5),
  .off-screens-for-main-only .turning-screens:nth-child(6) {
    display: none !important;
  }
  .coloctors-turning-pff .turning-screens:nth-child(5),
  .coloctors-turning-pff .turning-screens:nth-child(6) {
    display: block !important;
  }

  .author__followers {
    flex-direction: column;
    /* margin: 10px 10px 10px 10px; */
  }
  .author__wrap {
    display: flex;
    flex-direction: row;

    flex-wrap: nowrap;
    padding-top: 15px;
  }
  .author__meta a {
    padding: 0px 0px 0px 8px;
  }
}
@media (min-width: 1536px) {
  /* for 6 cards per row */
  .turning-screens {
    width: 20%;
  }

  .off-screens-for-main-only .turning-screens:nth-child(6) {
    display: none !important;
  }
  .coloctors-turning-pff .turning-screens:nth-child(6) {
    display: block !important;
  }

  .discover-screen1 {
    width: 20%;
  }
  .discover-screen2 {
    width: 80%;
  }
  .discover-screen-artist1 {
    width: 20%;
  }
  .discover-screen-artist2 {
    width: 80%;
  }
  .turning-screens-artist {
    width: 25%;
  }

  /* for 5 cards per row */
  .turning-screens.music-artists {
    width: 20%;
  }
  .turning-screens-discover {
    width: 25%;
  }
  /* .turning-screens:nth-child(6) {
    display: none !important;
  } */
  .author__followers {
    flex-direction: column;
    /* margin: 10px 10px 10px 10px; */
  }
  .author__wrap {
    display: flex;
    flex-direction: row;

    flex-wrap: nowrap;
    padding-top: 15px;
  }
  .author__meta a {
    padding: 0px 0px 0px 8px;
  }
}
